import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { TRIP_INVOICE } from "@utils/constants/trip/trip-invoice.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const tripInvoiceApi = createApi({
  reducerPath: "tripInvoiceApi",
  tagTypes: ["TripInvoice"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllTripInvoices: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        trip_sales_invoice_code?: string;
        search?: string;
      }
    >({
      query: ({ page = 1, limit = 10, trip_sales_invoice_code, search }) => {
        const queryParams = {
          url: TRIP_INVOICE.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (trip_sales_invoice_code) {
          //@ts-ignore
          queryParams.params.trip_sales_invoice_code = trip_sales_invoice_code;
        }

        return queryParams;
      },
    }),
    getTripInvoice: builder.query<any, { id: string | number }>({
      query: ({ id }) => {
        console.log(`${TRIP_INVOICE.GET_ONE}${id}`);
        return `${TRIP_INVOICE.GET_ONE}${id}`;
      },
    }),
    createTripInvoice: builder.mutation({
      query: (body) => ({
        url: TRIP_INVOICE.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateTripInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `${TRIP_INVOICE.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteTripInvoice: builder.mutation({
      query: (id) => ({
        url: `${TRIP_INVOICE.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllTripInvoicesQuery,
  useGetTripInvoiceQuery,
  useLazyGetTripInvoiceQuery,
  useCreateTripInvoiceMutation,
  useUpdateTripInvoiceMutation,
  useDeleteTripInvoiceMutation,
} = tripInvoiceApi;
