import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { MARKETING_PROMOTION_INVOICE } from "@utils/constants/marketing-promotion-invoice.constants";

export const marketingInvoiceApi = createApi({
  reducerPath: "marketingInvoiceApi",
  tagTypes: ["MarketingInvoice"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllMarketingInvoices: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        invoice_code?: string;
      }
    >({
      query: ({ page = 1, limit = 10, invoice_code }) => {
        const queryParams = {
          url: MARKETING_PROMOTION_INVOICE.GET_ALL,
          params: { page, limit },
        };

        if (invoice_code) {
          //@ts-ignore
          queryParams.params.invoice_code = invoice_code;
        }

        return queryParams;
      },
    }),
    getMarketingInvoice: builder.query<
      any,
      { marketing_promotion_invoice_id: string | number }
    >({
      query: ({ marketing_promotion_invoice_id }) => {
        return `${MARKETING_PROMOTION_INVOICE.GET_ONE}/${marketing_promotion_invoice_id}`;
      },
    }),
    createMarketingInvoice: builder.mutation({
      query: ({ body }) => ({
        url: `${MARKETING_PROMOTION_INVOICE.CREATE}`,
        method: "POST",
        body,
      }),
    }),
    updateMarketingInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_INVOICE.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteMarketingInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_INVOICE.DELETE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllMarketingInvoicesQuery,
  useGetMarketingInvoiceQuery,
  useCreateMarketingInvoiceMutation,
  useUpdateMarketingInvoiceMutation,
  useDeleteMarketingInvoiceMutation,
  useLazyGetMarketingInvoiceQuery,
} = marketingInvoiceApi;
