import Select from "react-select";
import CustomButton from "../buttons/CustomButton";
import { reactSelectStyles } from "../../assets/js/react-select-styles";
import useGetAllTrips from "@hooks/trip/useGetAllTrips";
import DatePicker from "react-datepicker";
import useGetAllCustomers from "@hooks/customer/useGetAllCustomers";
import useGetAllPaymentTypes from "@hooks/common/useGetAllPaymentTypes";
import { useState } from "react";
import { formatFilterDate } from "@utils/general/format-filter-date";
import { useGetAllDistributionRegionsQuery } from "@states/distribution-region/distribution-region.api";
import useGetAllRegions from "@hooks/distribution-region/useGetAllRegions";

const ConsignmentSalesOrderFilter = (props: {
  filterRef: React.MutableRefObject<{
    from_date: undefined;
    to_date: undefined;
    customer_id: undefined;
    status: undefined;
    payment_type_id: undefined;
    region_id: undefined;
  }>;
  refetch: () => void;
}) => {
  const { filterRef, refetch } = props;

  const { customerData } = useGetAllCustomers({ limit: 1000 });
  const { paymentTypeData } = useGetAllPaymentTypes();

  const {
    regionData,
    regionError,
    regionRefetch,
    regionFetching,
    regionPaginator,
  } = useGetAllRegions({
    limit: 1000,
  });
  const [curFromDate, setCurFromDate] = useState<Date | null>(null);
  const [curToDate, setCurToDate] = useState<Date | null>(null);

  const formattedCustomerData = customerData?.map((c) => ({
    label: c.customer_first_name || "",
    value: c.customer_id,
  }));
  const formattedDistributionData = regionData?.map((c: any) => ({
    label: c.region_name,
    value: c.region_id,
  }));

  const formattedPaymentTypeData = paymentTypeData?.map((pt) => ({
    label: pt.payment_type_name,
    value: pt.payment_type_id,
  }));

  const onChangeHandler = (val: any, key: string) => {
    const { value } = val;

    if (key === "from_date" || key === "to_date") {
      filterRef.current = {
        ...filterRef.current,
        [key]: formatFilterDate(val),
      };

      return;
    }

    filterRef.current = {
      ...filterRef.current,
      [key]: value,
    };
  };
  console.log("formattedDistributionData", formattedDistributionData);
  return (
    <div className="flex items-end mb-5">
      <div className="mr-6 basis-[20%] flex flex-col">
        <label className="text-gray-700 " htmlFor="name">
          From Date
        </label>
        <div
          // onClick={handleClick}
          className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
        >
          <DatePicker
            selected={curFromDate}
            wrapperClassName="w-full"
            className="ring-primary px-3 mt-[0.5rem] border-primary-light border-[0.5px] h-[34px] w-full block rounded"
            dateFormat={"dd MMM yyyy"}
            onChange={(val) => {
              setCurFromDate(val);
              onChangeHandler(val, "from_date");
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
            className={`absolute w-6 h-6 transform -translate-y-1/3 cursor-pointer top-1/2 right-3`}
          >
            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
          </svg>
        </div>
      </div>
      <div className="mr-6 basis-[20%] flex flex-col">
        <label className="text-gray-700 " htmlFor="name">
          To Date
        </label>
        <div
          // onClick={handleClick}
          className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
        >
          <DatePicker
            selected={curToDate}
            wrapperClassName="w-full"
            className="ring-primary px-3 mt-[0.5rem] border-primary-light border-[0.5px] h-[34px] w-full block rounded"
            dateFormat={"dd MMM yyyy"}
            onChange={(val) => {
              setCurToDate(val);
              onChangeHandler(val, "to_date");
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
            className={`absolute w-6 h-6 transform -translate-y-1/3 cursor-pointer top-1/2 right-3`}
          >
            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
          </svg>
        </div>
      </div>
      <div className="mr-6 basis-[20%]">
        <label className="mb-2 text-gray-700" htmlFor="name">
          Status
        </label>
        <Select
          styles={reactSelectStyles}
          placeholder="All"
          options={[
            { label: "All", value: undefined },
            { label: "Open", value: "1" },
            { label: "Invoice Created", value: "2" },
            { label: "Approve & Open", value: "3" },
            { label: "Rejected", value: "4" },
          ]}
          onChange={(val) => onChangeHandler(val, "status")}
        />
      </div>
      <div className="mr-6 basis-[20%]">
        <label className="mb-2 text-gray-700" htmlFor="name">
          Customer Name
        </label>
        <Select
          styles={reactSelectStyles}
          placeholder="All"
          options={
            formattedCustomerData && [
              { label: "All", value: undefined },
              ...formattedCustomerData,
            ]
          }
          onChange={(val) => onChangeHandler(val, "customer_id")}
        />
      </div>
      {/* <div className="mr-6 basis-[20%]">
        <label className="mb-2 text-gray-700" htmlFor="name">
          Payment Type Name
        </label>
        <Select
          styles={reactSelectStyles}
          placeholder="All"
          options={paymentTypeData
            ?.filter((item) => item.payment_type_name !== "Consignment")
            .map((item: any) => ({
              label: item.payment_type_name,
              value: item.payment_type_id,
            }))}
          onChange={(val) => onChangeHandler(val, "payment_type_id")}
        />
      </div> */}
      <div className="mr-6 basis-[20%]">
        <label className="mb-2 text-gray-700" htmlFor="name">
          Distribution Region Name
        </label>
        <Select
          styles={reactSelectStyles}
          placeholder="All"
          options={
            formattedDistributionData && [
              { label: "All", value: undefined },
              ...formattedDistributionData,
            ]
          }
          onChange={(val) => onChangeHandler(val, "region_id")}
        />
      </div>

      <div className="basis-1/6">
        <CustomButton
          handleClick={() => {
            refetch();
          }}
          className="w-24 h-[2.4rem]"
          title="Filter"
        />
      </div>
    </div>
  );
};

export default ConsignmentSalesOrderFilter;
