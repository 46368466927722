export const vehicleFields = (props?: any) => [
  {
    name: "vehicle_name",
    type: "text",
    label: "Vehicle Name",
    required: true,
  },
  {
    name: "description",
    type: "textarea",
    colSpan: 6,
  },
];
