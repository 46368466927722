import DeleteButton from "@components/buttons/DeleteButton";
import EditButton from "@components/buttons/EditButton";
import OutlinedStatus from "@components/status/OutlinedStatus";
import { thousandSeperator } from "@utils/general/digit-separators";
import { formatDate } from "@utils/general/format-date";

export const invoiceColumns = (
  editHandler: (invoice: any) => void,
  deleteHandler: (id: string | number) => void
) => [
  {
    name: "Invoice ID",
    selector: (row: any) => (
      <div className="font-semibold text-primary-dark">
        {row.consignment_invoice_code}
      </div>
    ),
    sort: true,
    width: "200px",
  },
  {
    name: "Consignment ID",
    selector: (row: any) => (
      <div className="font-semibold text-primary-dark">
        {row.consignment_code}
      </div>
    ),
    sort: true,
    width: "200px",
  },
  {
    name: "Invoice Date",
    selector: (row: any) => formatDate(new Date(row.invoice_date)),
    width: "150px   ",
  },
  {
    name: "Sales Date",
    selector: (row: any) => formatDate(new Date(row.sales_date)),
    width: "150px   ",
  },
  {
    name: "Customer Name",
    selector: (row: any) => row.customer_first_name || "-",
    width: "150px   ",
  },

  {
    name: "Business Unit Name",
    selector: (row: any) => row.business_unit_name || "-",
    width: "150px   ",
  },
  {
    name: "Region Name",
    selector: (row: any) => row.region_name || "-",
    width: "150px   ",
  },
  {
    name: "Grand Total Amount",
    selector: (row: any) => thousandSeperator(row.grand_total_amount),
    width: "150px   ",
  },
  {
    name: "Paid Amount",
    selector: (row: any) =>
      thousandSeperator(row.total_payment_receive_amount || 0),
    width: "150px   ",
  },
  {
    name: "Balance",
    selector: (row: any) => thousandSeperator(row.balance || 0),
    width: "150px   ",
  },
  {
    name: "Status",
    selector: (row: any) => (
      <OutlinedStatus
        // width="w-[13ch]"
        title={
          row.status == 1 ? "Open" : row.status == 2 ? "Partial Paid" : "Paid"
        }
        color={row.status == 1 ? "blue" : row.status == 2 ? "purple" : "green"}
      />
    ),
    // grow: 2,
    width: "150px   ",
  },
  {
    name: "Action",
    right: "true",
    selector: (row: any) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <EditButton
          disabled={row.status != 1 && row.status != 2}
          id={row.consignment_invoice_id}
          actionHandler={() => editHandler(row)}
        />
        <DeleteButton
          id={row.consignment_invoice_id}
          actionHandler={() => deleteHandler(row.consignment_invoice_id)}
        />
      </div>
    ),
  },
];
