import React from 'react';
import Modal from 'react-modal';
import CustomButton from '../../buttons/CustomButton';
import infoStyles from '../styles/infoStyles';

interface InfoModalProps {
    title?: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}

const InfoModal: React.FC<InfoModalProps> = ({
    title,
    isOpen,
    setIsOpen,
    children,
}) => {
    return (
        <Modal isOpen={isOpen} style={infoStyles} contentLabel="Example Modal">
            <div className="flex flex-col justify-between h-full">
                <div className="">
                    <div className="flex justify-end">
                        <svg
                            onClick={() => setIsOpen(false)}
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 -960 960 960"
                            width="20px"
                            fill="#3e3e3e"
                            className="cursor-pointer"
                        >
                            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                        </svg>
                    </div>
                    <div className="py-4 px-14">
                        <div className="flex items-center justify-between mb-6">
                            <h3 className="text-xl font-[600] uppercase text-primary-dark">
                                {title}
                            </h3>
                        </div>
                        <div className="">{children}</div>
                    </div>
                </div>
                <div className="flex justify-end pb-6 mt-8 mr-4">
                    <CustomButton
                        handleClick={() => setIsOpen(false)}
                        color="bg-primary-dark"
                        className="px-8 py-3"
                        title="Okay"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default InfoModal;
