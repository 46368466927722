import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { GOODS_REQUEST_NOTE } from "@utils/constants/goods-request/goods-request-note.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const goodsRequestApi = createApi({
  reducerPath: "goodsRequestApi",
  tagTypes: ["GoodsRequest"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllGoodsRequest: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
        customer_id?: string;
        status?: string;
        payment_type_id?: string;
        reference_type?: string;
        search?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        from_date,
        to_date,
        customer_id,
        status,
        payment_type_id,
        reference_type,
        search,
      }) => {
        const queryParams = {
          url: GOODS_REQUEST_NOTE.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }
        if (customer_id) {
          //@ts-ignore
          queryParams.params.customer_id = customer_id;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }
        if (reference_type) {
          //@ts-ignore
          queryParams.params.reference_type = reference_type;
        }

        return queryParams;
      },
    }),
    getInvoice: builder.query<[], { invoiceCode: string | number }>({
      query: ({ invoiceCode }) =>
        `${GOODS_REQUEST_NOTE.BASE_PATH}search/invoice?invoiceCode=${invoiceCode}`,
    }),

    getAllDirectMakePayments: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        direct_sales_return_code?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,

        direct_sales_return_code,
      }) => {
        const queryParams = {
          url: GOODS_REQUEST_NOTE.GET_ALL_MAKE_PAYMENTS,
          params: { page, limit },
        };

        if (direct_sales_return_code) {
          //@ts-ignore
          queryParams.params.direct_sales_return_code =
            direct_sales_return_code;
        }

        return queryParams;
      },
    }),

    getGoodsRequest: builder.query<any, { goods_request_id: string | number }>({
      query: ({ goods_request_id }) =>
        `${GOODS_REQUEST_NOTE.GET_ONE}${goods_request_id}`,
    }),
    createGoodsRequest: builder.mutation({
      query: (body) => ({
        url: GOODS_REQUEST_NOTE.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateGoodsRequest: builder.mutation({
      query: ({ id, body }) => ({
        url: `${GOODS_REQUEST_NOTE.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    createDirectMakePayment: builder.mutation({
      query: ({ direct_sales_return_id, body }) => ({
        url: `${GOODS_REQUEST_NOTE.MAKE_PAYMENT}${direct_sales_return_id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteGoodsRequest: builder.mutation({
      query: ({ id, body }) => ({
        url: `${GOODS_REQUEST_NOTE.DELETE}${id}`,
        method: "DELETE",
        body,
      }),
    }),
    deleteDirectMakePayment: builder.mutation({
      query: ({ direct_sales_return_payment_made_id, body }) => ({
        url: `${GOODS_REQUEST_NOTE.DELETE_MAKE_PAYMENT}${direct_sales_return_payment_made_id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllGoodsRequestQuery,
  useGetGoodsRequestQuery,
  useCreateGoodsRequestMutation,
  useUpdateGoodsRequestMutation,
  useDeleteGoodsRequestMutation,
  useCreateDirectMakePaymentMutation,
  useGetAllDirectMakePaymentsQuery,
  useDeleteDirectMakePaymentMutation,
  useLazyGetInvoiceQuery,
} = goodsRequestApi;
