import { createApi } from "@reduxjs/toolkit/query/react";
import { MARKETING_PROMOTION_PLAN } from "@utils/constants/marketing-promotion-plan.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const MarketingPromotionPlanApi = createApi({
  reducerPath: "marketingPromotionApi",
  tagTypes: ["MarketingPromotionPlan"],
  baseQuery: baseQueryWithReauth,
  //   baseQuery: fetchBaseQuery({
  //     baseUrl: getBaseUrl(),
  //   }),
  endpoints: (builder) => ({
    getAllPlans: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        status?: number;
        name?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ page = 1, limit = 10, status, name, from_date, to_date }) => {
        const queryParams = {
          url: MARKETING_PROMOTION_PLAN.GET_ALL,
          params: { page, limit },
        };

        if (name) {
          //@ts-ignore
          queryParams.params.name = name;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    getPlan: builder.query<any, { id: string | number }>({
      query: ({ id }) => {
        return MARKETING_PROMOTION_PLAN.GET_ONE(id);
      },
    }),
    createPlan: builder.mutation({
      query: (body) => ({
        url: MARKETING_PROMOTION_PLAN.CREATE,
        method: "POST",
        body,
      }),
    }),
    updatePlan: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_PLAN.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deletePlan: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_PLAN.DELETE}${id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllPlansQuery,
  useGetPlanQuery,
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useDeletePlanMutation,
} = MarketingPromotionPlanApi;
