import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { GOODS_ISSUE_NOTE } from "@utils/constants/goods-request/goods-issue-note.contants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const goodsIssueApi = createApi({
  reducerPath: "goodsIssueApi",
  tagTypes: ["GoodsIssue"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllGoodsIssue: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        customer_first_name?: string;
        direct_sales_invoice_code?: string;
        direct_sales_return_code?: string;
        search?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        customer_first_name,
        direct_sales_invoice_code,
        direct_sales_return_code,
        search,
      }) => {
        const queryParams = {
          url: GOODS_ISSUE_NOTE.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        // if (direct_sales_invoice_code) {
        //     //@ts-ignore
        //     queryParams.params.direct_sales_invoice_code =
        //         direct_sales_invoice_code;
        // }

        // if (direct_sales_return_code) {
        //     //@ts-ignore
        //     queryParams.params.direct_sales_return_code =
        //         direct_sales_return_code;
        // }

        return queryParams;
      },
    }),

    getGoodsIssue: builder.query<any, { goods_issue_id: string | number }>({
      query: ({ goods_issue_id }) =>
        `${GOODS_ISSUE_NOTE.GET_ONE}${goods_issue_id}`,
    }),
    createGoodsIssue: builder.mutation({
      query: (body) => ({
        url: GOODS_ISSUE_NOTE.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateGoodsIssue: builder.mutation({
      query: ({ id, body }) => ({
        url: `${GOODS_ISSUE_NOTE.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),

    deleteGoodsIssue: builder.mutation({
      query: ({ id, body }) => ({
        url: `${GOODS_ISSUE_NOTE.DELETE}${id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllGoodsIssueQuery,
  useGetGoodsIssueQuery,
  useLazyGetGoodsIssueQuery,
  useCreateGoodsIssueMutation,
  useUpdateGoodsIssueMutation,
  useDeleteGoodsIssueMutation,
} = goodsIssueApi;
