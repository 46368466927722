import { z } from "zod";

export const vehicleSchema = z.object({
  vehicle_name: z
    .string({
      message: "Vehicle Name is required",
    })
    .nonempty({
      message: "Vehicle Name is required",
    }),
  description: z.string().nullable(),
});
