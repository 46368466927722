import { z } from "zod";

export const paymentReceiveSchema = z.object({
  consignment_invoice_id: z.union([z.string(), z.number()]),
  consignment_invoice_code: z.string().optional(),
  payment_method_id: z.union([
    z.string({
      message: "Payment Method is required",
    }),
    z.number({
      message: "Payment Method is required",
    }),
  ]),
  file: z.any(),
  payment_receive_date: z.union([z.string(), z.date()], {
    message: "Payment receivedate date must be a valid date",
  }),
  payment_receive_amount: z.number(),
  invoiceData: z.object({
    // warehouse_id: z.union([z.string(), z.number()]),
    due_date: z.union([z.string(), z.date()], {
      message: "Sales date must be a valid date",
    }),
    payment_type_id: z.union([z.string(), z.number()]),
    grand_total_amount: z.number(),
    balance: z.number(),
  }),
});

export type ZPaymentReceive = z.infer<typeof paymentReceiveSchema>;
