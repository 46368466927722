import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { CONSIGNMENT_INVOICE } from "@utils/constants/consignment/consignment-invoice.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";
export const consignmentInvoiceApi = createApi({
  reducerPath: "consignmentInvoiceApi",
  tagTypes: ["ConsignemntInvoice"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllConsignmentInvoices: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
      }
    >({
      query: ({ page = 1, limit = 10, search }) => {
        const queryParams = {
          url: CONSIGNMENT_INVOICE.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        return queryParams;
      },
    }),
    getConsignmentInvoice: builder.query<
      any,
      { consignment_invoice_id: string | number }
    >({
      query: ({ consignment_invoice_id }) => {
        console.log(`${CONSIGNMENT_INVOICE.GET_ONE}${consignment_invoice_id}`);
        return `${CONSIGNMENT_INVOICE.GET_ONE}${consignment_invoice_id}`;
      },
    }),
    createConsignmentInvoice: builder.mutation({
      query: (body) => ({
        url: CONSIGNMENT_INVOICE.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateConsignmentInvoice: builder.mutation({
      query: ({ id, body }) => ({
        url: `${CONSIGNMENT_INVOICE.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteConsignmentInvoice: builder.mutation({
      query: (id) => ({
        url: `${CONSIGNMENT_INVOICE.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllConsignmentInvoicesQuery,
  useGetConsignmentInvoiceQuery,
  useLazyGetConsignmentInvoiceQuery,
  useCreateConsignmentInvoiceMutation,
  useUpdateConsignmentInvoiceMutation,
  useDeleteConsignmentInvoiceMutation,
} = consignmentInvoiceApi;
