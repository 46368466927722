import { useGetAllPaymentMethodQuery } from "@states/common/common.api";

const useGetAllPaymentMethod = () => {
  const {
    data: rawData,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllPaymentMethodQuery();

  const paymentMethodResponse = rawData as any as {
    payment_method_id: string;
    payment_method_name: string;
  }[];

  return {
    paymentMethodData: paymentMethodResponse,
    paymentMethodError: error,
    paymentMethodLoading: isLoading,
    paymentMethodRefetch: refetch,
    paymentMethodFetching: isFetching,
  };
};

export default useGetAllPaymentMethod;
