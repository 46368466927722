export const SALE_PROMOTION_PLAN = {
  PRIMARY_KEY: "user_id",
  BASE_PATH: "/sale-promotion/plans/",
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  RESET_PASSWORD_PATH(id: string | number) {
    return this.BASE_PATH + id + "/reset-password";
  },
  ADD_PAGE_TITLE: "Add New Sale Promotion Plan",
  LIST_PAGE_TITLE: "Sale Promotion Plan List",
  EDIT_PAGE_TITLE: "Edit Sale Promotion Plan",
  ADD_NEW_TITLE: "Add New Sale Promotion Plan",
  GET_ALL: "/sale-promotion",
  GET_ONE(id: string | number) {
    return `/sale-promotion/edit/${id}`;
  },
  CREATE: "/sale-promotion/create",
  UPDATE: "/sale-promotion/update/",
  DELETE: "/sale-promotion/delete/",
};
