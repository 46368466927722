import { getUploadUrl } from "@utils/general/getBaseUrl";
import React, { useEffect, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

interface CustomInputFieldProps {
  inputType?: string;
  colSpan?: number;
  name: string;
  label?: string;
  disabled?: boolean;
  noLabel?: boolean;
  placeHolder?: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  borderColor?: string;
  value?: string;
  showLabelOptional?: boolean;
  isFile?: boolean;
  isViewPdf?: boolean; // Added to check if view pdf button should be displayed
}

const CustomFileField: React.FC<CustomInputFieldProps> = ({
  inputType,
  name,
  label,
  disabled,
  noLabel,
  placeHolder,
  register,
  errors,
  colSpan,
  borderColor,
  value,
  showLabelOptional,
  isFile,
  isViewPdf, // Accessing the new prop
}) => {
  const [fileValue, setFileValue] = useState<undefined | string>(undefined);
  const [fileName, setFileName] = useState(undefined);
  const handleFileBrowseClick = () => {
    const fileInput = document.getElementById("my-file");

    if (fileInput) {
      fileInput.click();
    }
  };

  useEffect(() => {
    setFileValue(value);
  }, [value]);

  const handleViewPdf = (event: any) => {
    event.preventDefault();
    if (fileValue) {
      window.open(fileValue, "_blank");
    }
  };

  return (
    <div
      style={{
        gridColumn: colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
      }}
      className={`flex flex-col p-2 `}
    >
      {!noLabel ? (
        <label
          className="mb-2 text-gray-700"
          style={{ textTransform: "capitalize" }}
          htmlFor="name"
        >
          {label || name}
          {showLabelOptional ? (
            <span className="text-[0.7rem]"> (Optional)</span>
          ) : null}
        </label>
      ) : null}
      <div className=" flex flex-row ">
        <div>
          <input
            {...register(name, {
              //@ts-ignore
              onChange: (e) => {
                const file = e.target.files[0];
                setFileName(file.name);
                setFileValue(undefined);
              },
            })}
            type="file"
            style={{
              display: "none",
            }}
            id="my-file"
          />
        </div>
      </div>
      {value ? (
        <>
          {isFile ? (
            <div
              onClick={!fileValue ? handleFileBrowseClick : () => {}}
              className={`px-2 outline-dashed h-12 outline-[1px] outline-gray-300 flex items-center ${
                fileValue ? "justify-between" : "justify-center"
              } ${fileValue ?? "cursor-pointer"} py-3 w-full rounded-md ${
                borderColor ? borderColor : "border-primary-light"
              }`}
            >
              {fileName ?? fileValue ?? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#A9A9A9"
                >
                  <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                </svg>
              )}
              {fileValue && (
                <svg
                  className="cursor-pointer"
                  onClick={() => setFileValue(undefined)}
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="rgb(15,44,118)"
                >
                  <path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                </svg>
              )}
            </div>
          ) : (
            <div className="bg-gray-100">
              {isViewPdf ? (
                <embed
                  className="object-contain w-full rounded-t h-72"
                  src={value} // Display the PDF file as an embed
                  type="application/pdf"
                />
              ) : (
                <>
                  <img
                    className="object-contain w-full rounded-t h-72"
                    src={`${value}`}
                  />
                </>
              )}

              <div
                onClick={handleFileBrowseClick}
                className="flex items-center justify-between p-4 bg-white border-[1px] rounded-b cursor-pointer"
              >
                {fileName ? (
                  <div>{fileName}</div>
                ) : (
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="19px"
                      viewBox="0 -960 960 960"
                      width="19px"
                      fill="rgb(45,120,219)"
                    >
                      <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                    </svg>
                    <span className="ml-2 text-primary text-md">Replace</span>
                  </div>
                )}
                {/* Conditionally show the "View" button if isViewPdf is true */}

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="currentColor"
                    className="text-gray-400"
                  >
                    <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z" />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          onClick={handleFileBrowseClick}
          className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
        >
          {isFile ? (
            <div
              className={`px-2 outline-dashed h-12 outline-[1px] outline-gray-300 flex items-center justify-center cursor-pointer py-3 w-full rounded-md ${
                borderColor ? borderColor : "border-primary-light"
              }`}
            >
              {fileName ?? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#A9A9A9"
                >
                  <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                </svg>
              )}
            </div>
          ) : (
            <>
              <input
                disabled={disabled ? disabled : false}
                type="text"
                readOnly
                className={`px-2 outline-dashed outline-[1px] outline-gray-300 cursor-pointer py-3 w-full rounded-md ${
                  borderColor ? borderColor : "border-primary-light"
                }`}
                placeholder={
                  placeHolder
                    ? placeHolder
                    : `Enter ${label?.toLowerCase() || name}`
                }
                defaultValue={fileName}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="currentColor"
                className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
              >
                <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z" />
              </svg>
            </>
          )}
        </div>
      )}
      {isViewPdf ? (
        <button
          onClick={(e) => handleViewPdf(e)}
          className="mt-2 text-primary  border  bg-primary text-white py-2 rounded-lg "
        >
          View PDF
        </button>
      ) : null}
      {errors[name] && (
        <div className="mt-2 font-[300] text-red-600">
          {/* @ts-ignore */}
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default CustomFileField;
