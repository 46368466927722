import React, { useEffect, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";

interface CustomInputFieldProps {
  inputType?: string;
  colSpan?: number;
  name: string;
  label?: string;
  disabled?: boolean;
  noLabel?: boolean;
  placeHolder?: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  borderColor?: string;
  value?: any;
  showLabelOptional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  withHistory?: boolean;
  withEditIcon?: boolean;
  setShowHistoryModal?: any;
  classNames?: string;
  key?: string;
  autoFocus?: boolean;
  inputKey?: string;
  defaultValue?: any;
  hidden?: boolean;
  max?: number | string;
  min?: number | string;
  setValue?: any;
  removeSpaces?: boolean;
  rest?: any;
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
  inputType,
  name,
  label,
  disabled,
  noLabel,
  placeHolder,
  register,
  errors,
  colSpan,
  borderColor,
  value,
  showLabelOptional,
  readOnly,
  required,
  withHistory,
  setShowHistoryModal,
  classNames,
  key,
  autoFocus,
  inputKey,
  defaultValue,
  hidden,
  max,
  min,
  setValue,
  withEditIcon = true,
  removeSpaces,
  ...rest
}) => {
  const [allowEdit, setAllowEdit] = useState(withHistory);

  useEffect(() => {
    //@ts-ignore
    if (value && setValue) {
      setValue(name, value);
    }
  }, [value, setValue]);

  const handleOnKeyUp = (e: any) => {
    if (inputType === "number" && max) {
      //@ts-ignore
      if (e.target.value > max) {
        //@ts-ignore
        e.target.value = max;
      }
    } else if (inputType == "text" && removeSpaces) {
      //@ts-ignore
      e.target.value = e.target.value.replace(/^\s+/, "");
      console.log("e.target.value ", e.target.value);
    }
  };

  return (
    <div
      style={{
        gridColumn: colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
      }}
      className={`${hidden && "hidden"} flex flex-col p-2 ${classNames}`}
    >
      {!noLabel ? (
        <label
          className="mb-2 text-gray-700"
          style={{ textTransform: "capitalize" }}
          htmlFor="name"
        >
          {label || name}
          {showLabelOptional ? (
            <span className="text-[0.7rem]"> (Optional)</span>
          ) : null}
          {required ? (
            <span className="ml-[0.15rem] text-red-600 text-md">&#42;</span>
          ) : null}
        </label>
      ) : null}
      {withHistory ? (
        <div className="flex">
          <label className="relative block basis-[80%] xl:basis-[85%] 2xl:basis-[90%] mr-1 text-gray-400 cursor-pointer focus-within:text-gray-600">
            <input
              autoFocus={autoFocus}
              key={inputKey || name}
              {...register(name, {
                valueAsNumber: inputType == "number",
              })}
              disabled={disabled || allowEdit}
              readOnly={readOnly}
              min={1}
              type={inputType || "number"}
              className={`px-2 py-3 border-[0.7px] w-full focus:ring-1 ${
                readOnly ? "bg-zinc-100 cursor-not-allowed" : ""
              } ring-primary focus:outline-none rounded ${
                borderColor ? borderColor : "border-primary-light"
              }`}
              placeholder={
                placeHolder
                  ? placeHolder
                  : `Enter ${label?.toLowerCase() || name}`
              }
              defaultValue={defaultValue}
              // value={formattedValue}
              onKeyUp={handleOnKeyUp}
              {...rest}
            />
            {withEditIcon && (
              <svg
                onClick={() => setAllowEdit(!allowEdit)}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute transform -translate-y-1/2 cursor-pointer w-[1.80rem] h-[1.80rem] top-1/2 right-3"
              >
                <path
                  d="M16.7616 24.3763C16.2438 24.3763 15.8241 24.796 15.8241 25.3138C15.8241 25.8315 16.2438 26.2513 16.7616 26.2513H24.2616C24.7794 26.2513 25.1991 25.8315 25.1991 25.3138C25.1991 24.796 24.7794 24.3763 24.2616 24.3763H16.7616Z"
                  fill="#0054A6"
                />
                <path
                  d="M17.7542 5.71938L17.8667 5.78437C18.8394 6.34589 19.6505 6.81413 20.2444 7.27243C20.8712 7.75618 21.3688 8.31142 21.5771 9.08915C21.7855 9.86688 21.6323 10.5965 21.3313 11.3289C21.1224 11.8372 20.8151 12.4084 20.445 13.06L19.6508 12.588L19.6406 12.582L10.984 7.5841L10.1744 7.10696C10.5495 6.4681 10.8875 5.92243 11.2202 5.49125C11.704 4.8644 12.2592 4.36686 13.037 4.15847C13.8147 3.95008 14.5443 4.10333 15.2767 4.40434C15.9705 4.6895 16.7816 5.1578 17.7542 5.71938Z"
                  fill="#0054A6"
                />
                <path
                  d="M9.23513 8.7298L4.88767 16.2597C4.51543 16.9032 4.22111 17.412 4.11207 17.9908C4.00302 18.5695 4.09201 19.1506 4.20454 19.8854L4.23486 20.084C4.4426 21.4482 4.61382 22.5727 4.87207 23.4254C5.14216 24.3172 5.55736 25.0891 6.37394 25.5605C7.19053 26.032 8.06661 26.0056 8.97397 25.7936C9.84155 25.5909 10.901 25.177 12.1863 24.6748L12.3734 24.6017C13.0661 24.3318 13.6138 24.1183 14.0605 23.7345C14.5072 23.3507 14.8007 22.8414 15.1718 22.1972L19.509 14.6849L18.6979 14.2028L10.0356 9.20159L9.23513 8.7298Z"
                  fill="#0054A6"
                />
              </svg>
            )}
          </label>
          <div
            onClick={() => setShowHistoryModal((prev: boolean) => !prev)}
            className="basis-[20%] xl:basis-[15%] 2xl:basis-[10%] border-primary-light rounded border-[0.7px] ml-1 flex items-center justify-center cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="rgb(30,84,166)"
            >
              <path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" />
            </svg>
          </div>
        </div>
      ) : (
        <input
          key={inputKey || name}
          {...register(name, {
            valueAsNumber: inputType === "number",
          })}
          max={max}
          min={min}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
          type={inputType || "text"}
          className={`px-2 py-3 border-[0.7px] focus:ring-1 ${
            readOnly ? "bg-zinc-100 pointer-events-none cursor-not-allowed" : ""
          } ring-primary focus:outline-none rounded ${
            borderColor ? borderColor : "border-primary-light"
          }`}
          placeholder={
            placeHolder ? placeHolder : `Enter ${label?.toLowerCase() || name}`
          }
          onKeyUp={handleOnKeyUp}
        />
      )}

      {errors[name] && (
        <div className="mt-2 font-[300] italic text-red-600">
          {/* @ts-ignore */}
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default CustomInputField;
