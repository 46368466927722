import { TPrimarySaleOrder } from "@helpers/validation-schemas/primary-sale-order/sale-order.schema";
import DeleteButton from "@components/buttons/DeleteButton";
import { thousandSeperator } from "@utils/general/digit-separators";
import { formatDate } from "@utils/general/format-date";

export const consignmentReceiveColumns = (
  deleteHandler: (id: string | number) => void,
  handlePaymentReceiveModalOpen: (id: string | number) => any
) => [
  {
    name: "Payment Receive ID",
    selector: (row: any) => (
      <div
        className="italic font-semibold  text-primary-dark cursor-pointer underline"
        onClick={(e) =>
          handlePaymentReceiveModalOpen(row.consignment_payment_receive_id)
        }
      >
        {row.consignment_payment_receive_id}
      </div>
    ),
    sort: true,
  },
  {
    name: "Payment Receive Date",
    selector: (row: any) => formatDate(new Date(row.payment_receive_date)),
    sort: true,
  },
  {
    name: "Invoice ID",
    selector: (row: any) => row.consignment_invoice_code,
    sort: true,
  },
  {
    name: "Customer Name",
    selector: (row: any) => row.customer_name || row.customer_first_name,
    sort: true,
  },
  {
    name: "Business Unit Name",
    selector: (row: any) => row.business_unit_name,
    sort: true,
  },
  {
    name: "Payment Receive Amount",
    selector: (row: any) => thousandSeperator(row.payment_receive_amount || 0),
    sort: true,
  },
  // {
  //     name: 'Action',
  //     right: 'true',
  //     selector: (row: any) => (
  //         <div
  //             style={{
  //                 display: 'flex',
  //                 alignItems: 'center',
  //             }}
  //         >
  //             <DeleteButton
  //                 id={row.consignment_payment_receive_id}
  //                 actionHandler={deleteHandler}
  //             />
  //         </div>
  //     ),
  // },
];
