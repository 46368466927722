import { TTrip } from "@helpers/validation-schemas/trip/trip.schema";
import DeleteButton from "@components/buttons/DeleteButton";
import EditButton from "@components/buttons/EditButton";
import InfoButton from "@components/buttons/InfoButton";
import { formatDate, formatDateTime } from "@utils/general/format-date";
import { formatDBDate } from "@utils/general/format-db-date";
import OutlinedStatus from "@components/status/OutlinedStatus";

export const tripApprovalColumns = (
  editHandler: (id: string | number) => void,
  deleteHandler: () => void,
  toggleModalHandler: (id: any) => void
) => [
  {
    name: "Trip ID",
    selector: (row: TTrip) => (
      <div className="font-semibold text-primary-dark">{row.trip_code}</div>
    ),
    sort: true,
    grow: 3,
  },
  {
    name: "Trip Proposal Name",
    selector: (row: TTrip) => row.trip_name,
    sort: true,
    grow: 2,
  },
  {
    name: "Distribution Region",
    selector: (row: any) => row.region_name || "",
    sort: true,
    grow: 2,
  },

  // {
  //     name: 'Region Name',
  //     selector: (row: TTrip) => row.region_name,

  //     sort: true,
  // },
  {
    name: "Allocation Account Name",
    selector: (row: any) => (
      <div className="font-semibold text-primary-dark ">{`${row.trip_warehouse_count} Allocation Account Name`}</div>
    ),
    grow: 3,
  },
  {
    name: "Created Date",
    selector: (row: any) =>
      (row.created_time && formatDBDate(row.created_time)) || "",
    sort: true,
    grow: 2,
  },
  {
    name: "Updated Date",
    selector: (row: any) =>
      (row.updated_time && formatDBDate(row.updated_time)) || "-",
    sort: true,
    grow: 2,
  },
  {
    name: "Status",
    selector: (row: any) =>
      //@ts-ignore
      row.no !== "Totals:" && (
        <OutlinedStatus
          width="w-fit"
          color={
            row.status == 1
              ? "blue"
              : row.status == 2
              ? "green"
              : row.status == 3
              ? "purple"
              : row.status == 4
              ? "red"
              : "orange"
          }
          title={
            row.status == 1
              ? "Open"
              : row.status == 2
              ? "Invoice Created"
              : row.status == 3
              ? "Approved & Open"
              : row.status == 4
              ? "Rejected"
              : "Closed"
          }
        />
      ),
    // sortable: true,
    grow: 2.5,
  },
  {
    name: "Action",
    right: "true",
    selector: (row: any) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <InfoButton
          id={row.trip_id}
          actionHandler={() => toggleModalHandler(row.trip_id)}
        /> */}
        <EditButton id={row.trip_id} actionHandler={editHandler} />
        <DeleteButton
          id={row.trip_id}
          actionHandler={deleteHandler}
          disabled={true}
        />
      </div>
    ),
  },
];
