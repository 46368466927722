import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common/common.slice";
import authReducer from "./auth/auth.slice";
// APIs
import { commonApi } from "./common/common.api";
import { officeUserApi } from "./user-management/office-user.api";
import { customerCategoryApi } from "./customer/customer-category.api";
import { productCategoryApi } from "./product/product-category.api";
import { customerApi } from "./customer/customer.api";
import { supplierApi } from "./supplier/supplier.api";
import { businessUnitApi } from "./customer/business-unit.api";
import { productTagApi } from "./product/product-tag.api";
import { unitOfMeasurementApi } from "./unit/unit-of-measurement.api";
import { saleTargetApi } from "./sale-target/sale-target.api";
import { unitOfConversionApi } from "./unit/unit-of-conversion.api";
import { userRoleAssignApi } from "./user-management/user-role-assign.api";
import { distributionRegionApi } from "./distribution-region/distribution-region.api";
import { brandApi } from "./product/brand.api";
import { productApi } from "./product/product.api";
import { customerUserApi } from "./user-management/customer-user.api";
import { wayApi } from "./distribution-region/way.api";
import { wayUserAssignApi } from "./distribution-region/way-user-assign.api";
import { warehouseApi } from "./warehouse-and-inventory/warehouse.api";
import { warehouseUserAssignApi } from "./warehouse-and-inventory/warehouse-user-assign.api";
import { primarySaleOrderApi } from "./primary-sale-order/primary-sale-order.api";
import { primaryInvoiceApi } from "./primary-sale-order/primary-invoice.api";
import { purchaseBillApi } from "./purchase/purchase-bill.api";
import { paymentMadeApi } from "./purchase/payment-made.api";
import { primaryPaymentReceiveApi } from "./primary-sale-order/primary-payment-receive.api";
import { secondarySaleOrderApi } from "./secondary-sale-order/secondary-sale-order.api";
import { secondaryInvoiceApi } from "./secondary-sale-order/secondary-invoice.api";
import { secondaryPaymentReceiveApi } from "./secondary-sale-order/secondary-payment-receive.api";
import { directSaleOrderApi } from "./direct-sales-order/direct-sales-order.api";
import { directInvoiceApi } from "./direct-sales-order/direct-invoice.api";
import { directPaymentReceiveApi } from "./direct-sales-order/direct-payment-receive.api";
import { purchaseOrderApi } from "./purchase/purchase-order.api";
import { stockImportApi } from "./warehouse-and-inventory/stock-import.api";
import { stockTransferApi } from "./warehouse-and-inventory/stock-transfer.api";
import { stockAdjustmentApi } from "./warehouse-and-inventory/stock-adjustment.api";
import { stockBalanceApi } from "./warehouse-and-inventory/stock-balance.api";
import { primarySalesReturnApi } from "./primary-sales-return/sales-return.api";
import { secondarySalesReturnApi } from "./secondary-sales-return/sales-return.api";
import { directSalesReturnApi } from "./direct-sales-return/sales-return.api";
import { expneseApi } from "./expense-management/expense.api";
import { tripApi } from "./trip/trip.api";
import { tripSalesRequestApi } from "./trip/trip-sales-request.api";
import { authApi } from "./auth/auth.api";
import { tripUserAssignApi } from "./trip/trip-user-assign.api";
import { tripSalesOrderApi } from "./trip/trip-sales-order.api";
import { tripInvoiceApi } from "./trip/trip-invoice.api";
import { tripPaymentReceiveApi } from "./trip/trip-payment-receive.api";
import { tripSalesReturnApi } from "./trip/trip-sales-return.api";
import { consignmentReturnApi } from "./consignment-sales-return /consignment-return.api";
import { consignmentApi } from "./consignment/consignment.api";
import { consignmentInvoiceApi } from "./consignment/consignment-invoice.api";
import { consignmentPaymentReceiveApi } from "./consignment/consignment-payment-receive.api";
import { consignmentContractApi } from "./consignment/consignment-contract.api";
import { reportApi } from "./report/report.api";
import { goodReturnNoteApi } from "./good-return/good-return-note.api";
import { goodsRequestApi } from "./goods-request/goods-request.api";
import { goodsIssueApi } from "./goods-request/goods-issue.api";
import { deliveryNoteApi } from "./goods-request/delivery-note.api";
import { MarketingPromotionPlanApi } from "./marketing-promotion/plan.api";
import { marketingGoodRequisitionApi } from "./marketing-promotion/good-requisition.api";
import { marketingInvoiceApi } from "./marketing-promotion/invoice.api";
import { MarketingPaymentReceiveApi } from "./marketing-promotion/payment-receive.api";
import { SalePromotionPlanApi } from "./sale-promotion/plan.api";
import { vehicleApi } from "./trip/vehicle.api";
export const store = configureStore({
  reducer: {
    [commonApi.reducerPath]: commonApi.reducer,
    [officeUserApi.reducerPath]: officeUserApi.reducer,
    [customerCategoryApi.reducerPath]: customerCategoryApi.reducer,
    [productCategoryApi.reducerPath]: productCategoryApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [supplierApi.reducerPath]: supplierApi.reducer,
    [businessUnitApi.reducerPath]: businessUnitApi.reducer,
    [productTagApi.reducerPath]: productTagApi.reducer,
    [unitOfMeasurementApi.reducerPath]: unitOfMeasurementApi.reducer,
    [unitOfConversionApi.reducerPath]: unitOfConversionApi.reducer,
    [saleTargetApi.reducerPath]: saleTargetApi.reducer,
    [userRoleAssignApi.reducerPath]: userRoleAssignApi.reducer,
    [distributionRegionApi.reducerPath]: distributionRegionApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerUserApi.reducerPath]: customerUserApi.reducer,
    [wayApi.reducerPath]: wayApi.reducer,
    [wayUserAssignApi.reducerPath]: wayUserAssignApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [primarySaleOrderApi.reducerPath]: primarySaleOrderApi.reducer,
    [primaryInvoiceApi.reducerPath]: primaryInvoiceApi.reducer,
    [primaryPaymentReceiveApi.reducerPath]: primaryPaymentReceiveApi.reducer,
    [warehouseUserAssignApi.reducerPath]: warehouseUserAssignApi.reducer,
    [secondarySaleOrderApi.reducerPath]: secondarySaleOrderApi.reducer,
    [secondaryInvoiceApi.reducerPath]: secondaryInvoiceApi.reducer,
    [secondaryPaymentReceiveApi.reducerPath]:
      secondaryPaymentReceiveApi.reducer,
    [directSaleOrderApi.reducerPath]: directSaleOrderApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [directInvoiceApi.reducerPath]: directInvoiceApi.reducer,
    [directPaymentReceiveApi.reducerPath]: directPaymentReceiveApi.reducer,
    [purchaseOrderApi.reducerPath]: purchaseOrderApi.reducer,
    [purchaseBillApi.reducerPath]: purchaseBillApi.reducer,
    [paymentMadeApi.reducerPath]: paymentMadeApi.reducer,
    [stockImportApi.reducerPath]: stockImportApi.reducer,
    [stockTransferApi.reducerPath]: stockTransferApi.reducer,
    [stockAdjustmentApi.reducerPath]: stockAdjustmentApi.reducer,
    [stockBalanceApi.reducerPath]: stockBalanceApi.reducer,
    [primarySalesReturnApi.reducerPath]: primarySalesReturnApi.reducer,
    [secondarySalesReturnApi.reducerPath]: secondarySalesReturnApi.reducer,
    [directSalesReturnApi.reducerPath]: directSalesReturnApi.reducer,
    [expneseApi.reducerPath]: expneseApi.reducer,
    [tripApi.reducerPath]: tripApi.reducer,
    [consignmentReturnApi.reducerPath]: consignmentReturnApi.reducer,
    [tripSalesRequestApi.reducerPath]: tripSalesRequestApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [tripUserAssignApi.reducerPath]: tripUserAssignApi.reducer,
    [tripSalesOrderApi.reducerPath]: tripSalesOrderApi.reducer,
    [tripInvoiceApi.reducerPath]: tripInvoiceApi.reducer,
    [tripPaymentReceiveApi.reducerPath]: tripPaymentReceiveApi.reducer,
    [tripSalesReturnApi.reducerPath]: tripSalesReturnApi.reducer,
    [consignmentApi.reducerPath]: consignmentApi.reducer,
    [consignmentContractApi.reducerPath]: consignmentContractApi.reducer,
    [consignmentInvoiceApi.reducerPath]: consignmentInvoiceApi.reducer,
    [consignmentPaymentReceiveApi.reducerPath]:
      consignmentPaymentReceiveApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [goodReturnNoteApi.reducerPath]: goodReturnNoteApi.reducer,
    [goodsRequestApi.reducerPath]: goodsRequestApi.reducer,
    [goodsIssueApi.reducerPath]: goodsIssueApi.reducer,
    [deliveryNoteApi.reducerPath]: deliveryNoteApi.reducer,
    [MarketingPromotionPlanApi.reducerPath]: MarketingPromotionPlanApi.reducer,
    [SalePromotionPlanApi.reducerPath]: SalePromotionPlanApi.reducer,
    [marketingInvoiceApi.reducerPath]: marketingInvoiceApi.reducer,
    [marketingGoodRequisitionApi.reducerPath]:
      marketingGoodRequisitionApi.reducer,
    [MarketingPaymentReceiveApi.reducerPath]:
      MarketingPaymentReceiveApi.reducer,
    common: commonReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      commonApi.middleware,
      officeUserApi.middleware,
      customerCategoryApi.middleware,
      productCategoryApi.middleware,
      customerApi.middleware,
      supplierApi.middleware,
      businessUnitApi.middleware,
      productTagApi.middleware,
      unitOfMeasurementApi.middleware,
      unitOfConversionApi.middleware,
      saleTargetApi.middleware,
      userRoleAssignApi.middleware,
      distributionRegionApi.middleware,
      brandApi.middleware,
      productApi.middleware,
      customerUserApi.middleware,
      wayApi.middleware,
      wayUserAssignApi.middleware,
      warehouseApi.middleware,
      primarySaleOrderApi.middleware,
      primaryInvoiceApi.middleware,
      primaryPaymentReceiveApi.middleware,
      warehouseUserAssignApi.middleware,
      secondarySaleOrderApi.middleware,
      secondaryInvoiceApi.middleware,
      secondaryPaymentReceiveApi.middleware,
      directSaleOrderApi.middleware,
      vehicleApi.middleware,
      directInvoiceApi.middleware,
      directPaymentReceiveApi.middleware,
      stockImportApi.middleware,
      stockTransferApi.middleware,
      stockAdjustmentApi.middleware,
      stockBalanceApi.middleware,
      primarySalesReturnApi.middleware,
      secondarySalesReturnApi.middleware,
      directSalesReturnApi.middleware,
      purchaseOrderApi.middleware,
      purchaseBillApi.middleware,
      expneseApi.middleware,
      paymentMadeApi.middleware,
      tripApi.middleware,
      tripSalesRequestApi.middleware,
      authApi.middleware,
      tripUserAssignApi.middleware,
      tripSalesOrderApi.middleware,
      tripInvoiceApi.middleware,
      tripPaymentReceiveApi.middleware,
      tripSalesReturnApi.middleware,
      consignmentReturnApi.middleware,
      consignmentApi.middleware,
      consignmentContractApi.middleware,
      consignmentInvoiceApi.middleware,
      consignmentPaymentReceiveApi.middleware,
      reportApi.middleware,
      goodReturnNoteApi.middleware,
      goodsRequestApi.middleware,
      goodsIssueApi.middleware,
      deliveryNoteApi.middleware,
      MarketingPromotionPlanApi.middleware,
      marketingInvoiceApi.middleware,
      marketingGoodRequisitionApi.middleware,
      MarketingPaymentReceiveApi.middleware,
      SalePromotionPlanApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
