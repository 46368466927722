import React, { useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import BackButton from "../buttons/BackButton";
import CustomInputField from "./CustomInputField";
import CustomSelectField from "./CustomSelectField";
import CustomTextAreaField from "./CustomTextAreaField";
import CustomButton from "../buttons/CustomButton";
import CustomSearchModalSelectField from "./CustomSearchModalSelectField";
import CustomFileField from "./CustomFileField";
import CustomSearchModalChooseField from "./CustomSearchModalChooseField";
import CustomDateField from "./CustomDateField";
import { addFormValues, resetFormValues } from "@states/common/common.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@states/store";
import { Toast } from "@helpers/popups/Toast";
import CustomeSelectCreateField from "./CustomeSelectCreateField";
import CustomCheckboxGroup from "./CustomCheckboxGroup";
import CustomRadioGroup from "./CustomRadioGroup";

interface CustomFormProps {
  schema: any;
  fields: {
    name: string;
    type: string;
    label?: string;
    options?: {
      label: string;
      value: string | number;
    }[];
    columns?: {
      name: string;
      columnName: string;
    }[];
    disabled?: boolean;
    colSpan?: number;
    borderColor?: string;
    value?: any;
    placeHolder?: string;
    showLabelOptional?: boolean;
    title?: string;
    data?: unknown[];
    columnName?: string;
    idName?: string;
    nameName?: string;
    single?: boolean;
    readOnly?: boolean;
    action?: boolean;
    required?: boolean;
    selectIds?: any[];
    selectId?: string | number;
    withHistory?: boolean;
    setShowHistoryModal?: any;
    hidden?: boolean;
    removeSpaces?: boolean;
    defaultValue?: any;
    min?: number;
    max?: number;
    handleChange?: any;
  }[];
  formDisabled?: boolean;
  backbtn?: boolean;
  defaultValues?: any;
  asyncDispatcher: (data: any, reset: any) => void;
  getValuesByParent?: (getValues: any, setValue: any) => void;
  getValuesNow?: boolean;
  title?: string;
  customComponent?: React.ReactNode;
  customComponent2?: React.ReactNode;
}

const CustomForm: React.FC<CustomFormProps> = ({
  schema,
  formDisabled = false,
  fields,
  defaultValues,
  asyncDispatcher,
  title,
  customComponent,
  customComponent2,
  backbtn = true,
  getValuesByParent,
  getValuesNow,
}) => {
  type FormFields = z.infer<typeof schema>;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<FormFields>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  // Memoize defaultValues if necessary
  const stableDefaultValues = useMemo(() => defaultValues, [defaultValues]);

  useEffect(() => {
    reset(stableDefaultValues);
  }, [stableDefaultValues, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("value from ==>", value);
      dispatch(addFormValues(value));
    });

    return () => {
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch]);

  useEffect(() => {
    getValuesByParent && getValuesNow && getValuesByParent(getValues, setValue);
  }, [getValuesNow]);

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      // async stuff here
      // console.log(data, 'data');
      await asyncDispatcher(data, reset);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  console.log(errors, getValues(), "errors");

  return (
    <React.Fragment>
      {/* TODO: move the back button to the PageWrapper */}
      {backbtn && <BackButton />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
          {title}
        </h3>
        <div className="grid grid-cols-6 gap-x-4 gap-y-8">
          {fields.map((field) => {
            const commonProps = {
              errors,
              name: field.name,
              label: field.label || undefined,
              disabled: field.disabled,
              colSpan: field.colSpan,
              required: field.required,
              placeHolder: field.placeHolder,
            };

            switch (field.type) {
              case "select":
                return (
                  <CustomSelectField
                    key={field.name}
                    {...commonProps}
                    control={control}
                    options={field.options}
                    //@ts-ignore
                    value={field.value}
                  />
                );
              case "select-create":
                return (
                  <CustomeSelectCreateField
                    key={field.name}
                    {...commonProps}
                    control={control}
                    options={field.options}
                    //@ts-ignore
                    value={field.value}
                    handleChange={field.handleChange}
                  />
                );
              case "radio":
                return (
                  <CustomRadioGroup
                    key={field.name}
                    {...commonProps}
                    control={control}
                    //@ts-ignore
                    options={field.options}
                  />
                );
              case "file":
                return (
                  <CustomFileField
                    key={field.name}
                    {...commonProps}
                    register={register}
                    inputType={field.type}
                    borderColor={field.borderColor}
                    value={field.value}
                    placeHolder={field.placeHolder}
                    //@ts-ignore
                    isViewPdf={field.isViewPdf} // M
                    showLabelOptional={field.showLabelOptional}
                  />
                );
              case "date-picker":
                return (
                  <CustomDateField
                    {...commonProps}
                    register={register}
                    key={field.name}
                    control={control}
                    required={field.required}
                  />
                );
              case "custom-component":
                return (
                  <div key={field.name} className="col-span-full">
                    {customComponent}
                  </div>
                );
              case "custom-component-2":
                return (
                  <div key={field.name} className="col-span-full">
                    {customComponent2}
                  </div>
                );
              case "textarea":
                return (
                  <CustomTextAreaField
                    key={field.name}
                    {...commonProps}
                    register={register}
                  />
                );
              case "check-box":
                return (
                  <CustomCheckboxGroup
                    key={field.name}
                    //@ts-ignore
                    options={field.options}
                    {...commonProps}
                    control={control}
                    register={register}
                  />
                );
              case "empty-column":
                return <div key={field.name}></div>;
              case "secondary-title":
                return (
                  <div
                    key={field.name}
                    className={`${field.hidden && "hidden"} col-span-6`}
                  >
                    <h3 className="ml-2 font-[600] text-primary-dark">
                      <span className=" text-[1.1rem]">{field.name}</span>
                      {field.required && (
                        <span className="ml-1 text-red-500 text-[1.1rem]">
                          *
                        </span>
                      )}
                    </h3>
                  </div>
                );
              case "search-modal":
                return (
                  <CustomSearchModalSelectField
                    key={field.name}
                    {...commonProps}
                    title={field.title}
                    register={register}
                    borderColor={field.borderColor}
                    placeHolder={field.placeHolder}
                    setValue={setValue}
                    data={field.data}
                    columnName={field.columnName}
                    idName={field.idName}
                    nameName={field.nameName}
                    single={field.single}
                    action={field.action}
                    getValues={getValues}
                    value={field.value}
                    selectIds={field.selectIds}
                    selectId={field.selectId}
                    required={field.required}
                    columns={field.columns}
                  />
                );
              case "search-modal-choose":
                return (
                  <CustomSearchModalChooseField
                    key={field.name}
                    {...commonProps}
                    title={field.title}
                    columns={field.columns}
                    register={register}
                    borderColor={field.borderColor}
                    placeHolder={field.placeHolder}
                    setValue={setValue}
                    data={field.data}
                    hidden={field.hidden}
                    columnName={field.columnName}
                    idName={field.idName}
                    value={field.value}
                    nameName={field.nameName}
                    required={field.required}
                  />
                );

              case "add-btn":
                return (
                  <CustomButton
                    isLarge
                    title="Cancel"
                    color="white"
                    textColor="gray.800"
                    className="mr-10"
                    handleClick={() => navigate(-1)}
                  />
                );
              default:
                return (
                  <CustomInputField
                    min={field.min}
                    max={field.max}
                    setValue={setValue}
                    hidden={field.hidden}
                    key={field.name}
                    defaultValue={field.defaultValue}
                    {...commonProps}
                    register={register}
                    inputType={field.type}
                    borderColor={field.borderColor}
                    value={field.value}
                    placeHolder={field.placeHolder}
                    showLabelOptional={field.showLabelOptional}
                    readOnly={field.readOnly}
                    withHistory={field.withHistory}
                    setShowHistoryModal={field.setShowHistoryModal}
                    removeSpaces={field.removeSpaces}
                    required={field.required}
                  />
                );
            }
          })}
        </div>
        <div className="ml-2 mt-14">
          <CustomButton
            isLarge
            title="Cancel"
            color="white"
            textColor="gray.800"
            className="mr-10"
            handleClick={() => navigate(-1)}
          />
          <CustomButton
            handleClick={handleSubmit(onSubmit)}
            isLarge
            title={isSubmitting ? "Loading..." : "Submit"}
            disabled={isSubmitting || formDisabled}
            type="submit"
          />
        </div>
        {errors.root && (
          <div className="mt-6 text-red-500">{errors.root.message}</div>
        )}
      </form>
    </React.Fragment>
  );
};

export default CustomForm;
