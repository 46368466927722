import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { WAY } from "@utils/constants/way.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const wayApi = createApi({
  reducerPath: "wayApi",
  tagTypes: ["Way"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllWays: builder.query<
      [],
      { page?: number; limit?: number; search?: string }
    >({
      query: ({ page = 1, limit = 10, search }) => {
        const queryParams = {
          url: WAY.GET_ALL,
          params: { page, limit },
        };

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        return queryParams;
      },
    }),
    getWay: builder.query<[], { wayId: string | number }>({
      query: ({ wayId }) => {
        return `${WAY.GET_ONE}${wayId}`;
      },
    }),
    createWay: builder.mutation({
      query: (body) => ({
        url: WAY.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateWay: builder.mutation({
      query: ({ id, body }) => ({
        url: `${WAY.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteWay: builder.mutation({
      query: (id) => ({
        url: `${WAY.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllWaysQuery,
  useCreateWayMutation,
  useUpdateWayMutation,
  useDeleteWayMutation,
  useGetWayQuery,
} = wayApi;
