import { useGetAllReturnReasonQuery } from "@states/common/common.api";

const useGetAllReturnReason = () => {
  const {
    data: rawData,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllReturnReasonQuery();

  const returnReasonResponse = rawData as any as {
    return_reason_id: string;
    reason_name: string;
  }[];

  return {
    returnReasonData: returnReasonResponse,
    returnReasonError: error,
    returnReasonLoading: isLoading,
    returnReasonRefetch: refetch,
    returnReasonFetching: isFetching,
  };
};

export default useGetAllReturnReason;
