import { TWarehouse } from "@helpers/validation-schemas/warehouse-and-inventory/warehouse.schema";
import { TRegion } from "@type-defs/distribution-region/TRegion";
import { TWay } from "@type-defs/distribution-region/TWay";
// {
//     data: {
//         warehouses: TWarehouse[],states:any[], cities:any[],townships:any[]
//     };
//     // loadings: {
//     //     regionLoading: boolean;
//     // };
//     selectActions: {
//         warehouseSelectionAction : any;
//         state: any,city: any,township: any
//     };
//     disabled: {
//         cityDisabled: boolean;
//         townshipDisabled: boolean;
//     };
//     selectedIds: {
//         warehouseSelectedIds : any;
//         division: string[] | number[];
//         city: string[] | number[];
//         township: string[] | number[];
//     };

//     // values?: {
//     //     country :any
//     // };
// }

export const tripFields = ({
  data: {
    warehouses,
    states,
    cities,
    townships,
    regionData,
    vehicleData,
    officeUserData,
  },
  selectActions: {
    warehouseSelectionAction,
    stateSelectionAction,
    citySelectionAction,
    townshipSelectionAction,
    vehicleSelectionAction,
    userSelectionAction,
  },
  disabled: { cityDisabled, townshipDisabled },
  selectedIds: {
    warehouseSelectedIds,
    divisionSelectedIds,
    citySelectedIds,
    townshipSelectedIds,
    vehicleSelectedIds,
    userSelectedIds,
  },
  values: { warehousesValue, PhotoValue, StatusValue, ReasonValue },
}: // selectMultipleIds:{
//     warehouseSelectMutiIds
// }

any) => {
  return [
    {
      name: "trip_name",
      type: "text",
      label: "Trip Name",
      required: true,
      // removeSpaces : true
      disabled: true,
    },
    {
      name: "proposal_date",
      disabled: true,

      type: "date-picker",
      placeHolder: "Select Proposal Date",
      label: "Proposal Date",
      required: true,
    },
    {
      name: "region_id",
      type: "select",
      label: "Distribution Region Name",
      options: regionData.map((s: any) => ({
        value: s.region_id,
        label: s.region_name,
      })),
      //   disabled: unitLoading,
      //   value: unitValue,
      required: true,
      disabled: true,
    },
    {
      name: "warehouse_id",
      title: "Allocation Account List",
      columnName: "Allocation Account Name",
      idName: "warehouse_id",
      nameName: "warehouse_name",
      type: "search-modal",
      label: "Allocation Account Name",
      single: false,
      disabled: true,

      // disabled: regionLoading,
      placeHolder: "Choose allocation account name",
      data: warehouses?.map((w: any) => ({
        warehouse_id: w.warehouse_id,
        warehouse_name: w.warehouse_name,
        main_warehouse_name: w.main_warehouse_name,
        warehouse_address: w.warehouse_address || "-",
      })),
      action: warehouseSelectionAction,
      selectIds: warehouseSelectedIds,
      required: true,

      columns: [
        {
          name: "main_warehouse_name",
          columnName: "Main Allocation Account Name",
        },
        {
          name: "warehouse_name",
          columnName: "Allocation Account Name",
        },
        {
          name: "warehouse_address",
          columnName: "Address",
        },
      ],
      value: warehousesValue,
    },

    {
      name: "vehicle_id",
      title: "Vehicle List",
      columnName: "Vehicle Name",
      idName: "vehicle_id",
      nameName: "vehicle_name",
      disabled: true,

      type: "search-modal",
      label: "Vehicle Name",
      single: false,
      // disabled: regionLoading,
      placeHolder: "Choose Vehicle name",
      data: vehicleData?.map((w: any) => ({
        vehicle_id: w.vehicle_id,
        vehicle_name: w.vehicle_name,
      })),
      action: vehicleSelectionAction,
      selectIds: vehicleSelectedIds,
      required: true,
      columns: [
        {
          name: "vehicle_name",
          columnName: "Vehicle Name",
        },
      ],
      // value: warehousesValue,
      // selectIds: warehouseSelectMutiIds,
    },

    // {
    //   name: "vehicle_id",
    //   type: "select",
    //   label: "Vehicle Name",
    //   options: vehicleData.map((s: any) => ({
    //     value: s.vehicle_id,
    //     label: s.vehicle_name,
    //   })),
    //   //   disabled: unitLoading,
    //   //   value: unitValue,
    //   required: true,
    // },

    {
      name: "user_id",
      title: "Assignee List",
      disabled: true,

      columnName: "Assignee Name",
      idName: "user_id",
      nameName: "user_name",
      type: "search-modal",
      label: "Assignee Name",
      single: false,
      // disabled: regionLoading,
      placeHolder: "Choose Assignee name",
      data: officeUserData?.map((ou: any) => ({
        user_id: ou.user_id,
        user_name: ou.username,
        phone_number: ou.phone1,
        user_role_name: ou.user_role_name,
      })),
      action: userSelectionAction,
      selectIds: userSelectedIds,
      required: true,
      columns: [
        {
          name: "user_name",
          columnName: "User Name",
        },
        {
          name: "phone_number",
          columnName: "Phone Number",
        },
        {
          name: "user_role_name",
          columnName: "Role",
        },
      ],
      // value: warehousesValue,
      // selectIds: warehouseSelectMutiIds,
    },
    {
      name: "attachment",
      disabled: true,

      type: "file",
      label: "Attachment File",
      value: PhotoValue,
      // isViewPdf: PhotoValue ? true : false,
    },
    // {
    //   name: "user_id",
    //   title: "Assignee Name List",
    //   columnName: "Assignee Name",
    //   required: true,
    //   columns: [
    //     {
    //       name: "user_name",
    //       columnName: "User Name",
    //     },
    //     {
    //       name: "phone_number",
    //       columnName: "Phone Number",
    //     },
    //     {
    //       name: "user_role_name",
    //       columnName: "Role",
    //     },
    //   ],
    //   idName: "user_id",
    //   nameName: "user_name",
    //   type: "search-modal-choose",
    //   label: "Assignee Name",
    //   placeHolder: "Choose Assignee Name",
    //   data: officeUserData?.map((ou: any) => ({
    //     user_id: ou.user_id,
    //     user_name: ou.username,
    //     phone_number: ou.phone1,
    //     user_role_name: ou.user_role_name,
    //   })),
    // },

    {
      name: "description",
      type: "textarea",
      colSpan: 6,
    },
    // {
    //   name: "Add Trip Detail",
    //   type: "custom-component",
    // },
    {
      name: "state_id",
      disabled: true,

      title: "Division/State List",
      columnName: "Division/State Name",
      idName: "state_id",
      nameName: "name",
      type: "search-modal",
      label: "State/Division Name",
      placeHolder: "Select State/Division",
      data: states.map((s: any) => ({
        state_id: s.stateid,
        name: s.name,
      })),
      action: stateSelectionAction,
      selectIds: divisionSelectedIds,
      required: true,
      columns: [
        {
          name: "name",
          columnName: "State/Division Name",
        },
      ],
    },
    {
      name: "city_id",
      title: "City List",
      columnName: "City Name",
      idName: "city_id",
      nameName: "cityname",
      type: "search-modal",
      label: "City Name",
      placeHolder: "Select City Name",
      disabled: true,

      data: cities.map((c: any) => ({
        city_id: c.cityid,
        cityname: c.cityname,
        state_id: c.state_id,
        state_name: c.state_name,
      })),
      action: citySelectionAction,
      selectIds: citySelectedIds,
      required: true,
      columns: [
        {
          name: "cityname",
          columnName: "City Name",
        },
        {
          name: "state_name",
          columnName: "State/Division Name",
        },
      ],
    },
    {
      name: "township_id",
      title: "Township List",
      columnName: "Township Name",
      idName: "township_id",
      disabled: true,

      nameName: "townshipname",
      type: "search-modal",
      label: "Township Name",
      placeHolder: "Select Township Name",
      data: townships?.map((t: any) => ({
        township_id: t.townshipid,
        townshipname: t.townshipname,
        city_id: t.city_id,
        city_name: t.city_name,
        state_id: t.state_id,
        state_name: t.state_name,
      })),
      action: townshipSelectionAction,
      selectIds: townshipSelectedIds,
      required: true,
      columns: [
        {
          name: "townshipname",
          columnName: "Township Name",
        },
        {
          name: "city_name",
          columnName: "City Name",
        },
        {
          name: "state_name",
          columnName: "State/Division Name",
        },
      ],
    },

    {
      name: "Empty Column 2",
      type: "empty-column",
    },
    {
      name: "Empty Column 3",
      type: "empty-column",
    },

    {
      name: "Trip Detail List",
      type: "custom-component",
    },
    {
      name: "Add Product Detail",
      type: "custom-component-2",
    },
    {
      name: "status",
      type: "select",
      label: "Status",
      options: [
        {
          label: "Approve & Open",
          value: 3,
        },
        {
          label: "Reject",
          value: 4,
        },
      ],
      required: true,
      value: StatusValue,
    },
    {
      name: "reason",
      type: "text",
      label: "Reason",
      required: true,
      value: ReasonValue,
      // removeSpaces : true
    },
    // {
    //     name: 'remark',
    //     type: 'textarea',
    //     colSpan: 3,
    // },
  ];
};

{
  /* <CustomSelectField
                        //@ts-ignore
                        control={control}
                        errors={errors}
                        name="status"
                        label="Status"
                        options={[
                            {
                                label: 'Approve & Open',
                                value: 3,
                            },
                            {
                                label: 'Reject',
                                value: 4,
                            },
                        ]}
                    />
                    <CustomInputField
                        register={register}
                        name="reason"
                        errors={errors}
                        colSpan={4}
                    /> */
}
/// OLd

// import { TWarehouse } from '@helpers/validation-schemas/warehouse-and-inventory/warehouse.schema';
// import { TRegion } from '@type-defs/distribution-region/TRegion';
// import { TWay } from '@type-defs/distribution-region/TWay';

// export const tripFields = ({
//     data: { regions, ways, warehouses ,states ,cities , townships,cityDisabled},
//     loadings: { regionLoading },
//     selectActions: { regionSelectAction, waySelectAction  , allocationSelectAction,
//         citySelectAction,townshipSelectAction,stateSelectAction
//     },
//     selectedIds: { regionSelectedIds, waySelectedIds ,
//         townshipSelectId,
//         allocationSelectedIds,divisionSelectId, citySelectId},
//     // values:{
//     //     // cityValue,townshipValue,divisionValue
//     // },
// }: {
//     data: {
//         regions: TRegion[];
//         ways: TWay[];
//         warehouses: TWarehouse[];
//         states : any[];
//         cities:any[];
//         townships:any[];
//         cityDisabled?:boolean
//     };
//     loadings: {
//         regionLoading: boolean;
//     };
//     selectActions: {
//         regionSelectAction: any;
//         waySelectAction: any;
//         allocationSelectAction:any;
//         citySelectAction:any;
//         townshipSelectAction:any;
//         stateSelectAction:any;
//     };
//     selectedIds: {
//         regionSelectedIds: number[];
//         waySelectedIds: number[];
//         allocationSelectedIds:number[];
//         divisionSelectId:number[];
//         citySelectId:number[];
//         townshipSelectId:number[];
//     };

//     // values?: {
//     //     divisionValue?: string;
//     //     cityValue?: string;
//     //     townshipValue?: string;
//     // };
// }) => {

//     return [
//         {
//             name: 'trip_name',
//             type: 'text',
//             label: 'Trip Name',
//             required: true,
//         },
//         //TODO: bind actual API data
//         // {
//         //     name: 'warehouse_id',
//         //     title: 'Allocation Account List',
//         //     columnName: 'Allocation Account Name',
//         //     columns: [
//         //         {
//         //             name: 'vehicle_name',
//         //             columnName: 'Allocation Account Name',
//         //         },
//         //         {
//         //             name: 'warehouse_address',
//         //             columnName: 'Address',
//         //         },
//         //     ],
//         //     idName: 'warehouse_id',
//         //     nameName: 'warehouse_name',
//         //     type: 'search-modal-choose',
//         //     single : false,
//         //     label: 'Allocation Account Name',
//         //     placeHolder: 'Choose allocation account name',
//         //     data: warehouses?.map((w) => ({
//         //         warehouse_id: w.warehouse_id,
//         //         warehouse_name: w.warehouse_name,
//         //         warehouse_address: w.warehouse_address || '-',
//         //     })),
//         //     value: values?.warehouse,
//         //     required: true,
//         // },
//         {
//             name: 'warehouse_id',
//             title: 'Allocation Account List',
//             columnName: 'Allocation Account Name',
//             idName: 'warehouse_id',
//             nameName: 'warehouse_name',
//             type: 'search-modal',
//             label: 'Allocation Account Name',
//             single: false,
//             disabled: regionLoading,
//             placeHolder: 'Choose allocation account name',
//             data: warehouses?.map((w) => ({
//                 warehouse_id: w.warehouse_id,
//                 warehouse_name: w.warehouse_name,
//                 warehouse_address: w.warehouse_address || '-',
//             })),
//             action: allocationSelectAction,
//             selectIds: allocationSelectedIds,
//             required: true,
//             columns: [
//                 {
//                     name: 'warehouse_name',
//                     columnName: 'Allocation Account Name',
//                 },
//                 {
//                     name: 'warehouse_address',
//                     columnName: 'Address',
//                 },
//             ],
//         },
//         {
//             name: 'Empty Column 1',
//             type: 'empty-column',
//         },
//         // {
//         //     name: 'region_id',
//         //     title: 'Region List',
//         //     columnName: 'Region Name',
//         //     idName: 'region_id',
//         //     nameName: 'region_name',
//         //     type: 'search-modal',
//         //     label: 'Region Name',
//         //     single: false,
//         //     disabled: regionLoading,
//         //     placeHolder: 'Select region name',
//         //     data: regions?.map((r) => ({
//         //         //@ts-ignore
//         //         region_id: r.region_id,
//         //         region_name: r.region_name,
//         //     })),
//         //     action: regionSelectAction,
//         //     selectIds: regionSelectedIds,
//         //     required: true,
//         //     columns: [
//         //         {
//         //             name: 'region_name',
//         //             columnName: 'Region Name',
//         //         },
//         //     ],
//         // },
//         // {
//         //     name: 'way_id',
//         //     title: 'Way List',
//         //     columnName: 'Way Name',
//         //     idName: 'way_id',
//         //     nameName: 'way_name',
//         //     type: 'search-modal',
//         //     label: 'Way Name',
//         //     single: false,
//         //     disabled: regionLoading,
//         //     placeHolder: 'Select way name',
//         //     data: ways?.map((w) => ({
//         //         //@ts-ignore
//         //         way_id: w.way_id,
//         //         way_name: w.way_name,
//         //     })),
//         //     action: waySelectAction,
//         //     selectIds: waySelectedIds,
//         //     required: true,
//         //     columns: [
//         //         {
//         //             name: 'way_name',
//         //             columnName: 'Way Name',
//         //         },
//         //     ],
//         // },
//         {
//             name: 'state_name',
//             title: 'Division/State List',
//             columnName: 'Division/State Name',
//             idName: 'state_id',
//             nameName: 'name',
//             type: 'search-modal',
//             label: 'State/Division Name',
//             placeHolder: 'Select State/Division',
//             data: states?.map((s) => ({
//                 state_id: s.stateid,
//                 name: s.name,
//             })),
//             single: true,
//             action: stateSelectAction,
//             selectId: divisionSelectId,
//             columns: [
//                 {
//                     name: 'name',
//                     columnName: 'State/Division Name',
//                 },
//             ],
//             value: divisionValue,
//         },
//         {
//             name: 'city_name',
//             title: 'City List',
//             columnName: 'City Name',
//             idName: 'city_id',
//             nameName: 'cityname',
//             type: 'search-modal',
//             label: 'City Name',
//             placeHolder: 'Select City Name',
//             data: cities?.map((c) => ({
//                 city_id: c.cityid,
//                 cityname: c.cityname,
//                 state_id: c.state_id,
//                 state_name: c.state_name,
//             })),
//             action: citySelectAction,
//             selectId: citySelectId,
//             single: true,
//             disabled: cityDisabled,
//             columns: [
//                 {
//                     name: 'cityname',
//                     columnName: 'City Name',
//                 },
//                 {
//                     name: 'state_name',
//                     columnName: 'State/Division Name',
//                 },
//             ],
//             value: cityValue,
//         },
//         {
//             name: 'townshipname',
//             title: 'Township List',
//             columnName: 'Township Name',
//             idName: 'township_id',
//             nameName: 'townshipname',
//             type: 'search-modal',
//             label: 'Township Name',
//             placeHolder: 'Select Township Name',
//             data: townships?.map((t) => ({
//                 township_id: t.townshipid,
//                 townshipname: t.townshipname,
//                 city_id: t.city_id,
//                 city_name: t.city_name,
//                 state_id: t.state_id,
//                 state_name: t.state_name,
//             })),
//             single: true,
//             disabled: townshipDisabled,
//             action: townshipSelectAction,
//             selectId: townshipSelectId,
//             columns: [
//                 {
//                     name: 'townshipname',
//                     columnName: 'Township Name',
//                 },
//                 {
//                     name: 'city_name',
//                     columnName: 'City Name',
//                 },
//                 {
//                     name: 'state_name',
//                     columnName: 'State/Division Name',
//                 },
//             ],
//             value: townshipValue,
//         },
//         {
//             name: 'Empty Column 2',
//             type: 'empty-column',
//         },
//         {
//             name: 'Empty Column 3',
//             type: 'empty-column',
//         },
//         {
//             name: 'Trip Detail List',
//             type: 'custom-component',
//         },
//         {
//             name: 'remark',
//             type: 'textarea',
//             colSpan: 3,
//         },
//         {
//             name: 'description',
//             type: 'textarea',
//             colSpan: 3,
//         },
//     ];
// };
