import CustomForm from "@components/form/CustomForm";
import CustomSearchModalSelectField from "@components/form/CustomSearchModalSelectField";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import PageWrapper from "@components/layouts/PageWrapper";
import useGetAllWarehouses from "@hooks/warehouse-and-inventory/useGetAllWarehouses";
import { TRIP_MANAGEMENT } from "@utils/constants/trip/trip.constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { removeKeys } from "@utils/general/removeKeys";
import {
  useCreateTripMutation,
  useGetTripQuery,
  useUpdateTripApprovalMutation,
} from "@states/trip/trip.api";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "@states/store";
import { LeavingSpinner } from "@components/layouts/LeavingSpinner";
import { tripFields } from "@helpers/fields/trip/trip-approval.fields";
import { tripSchema } from "@helpers/validation-schemas/trip/trip.schema";
import {
  addRegions,
  addTripDetails,
  addWays,
  addAllocations,
  addUsers,
  addVehicles,
} from "@states/common/common.slice";
import {
  addCities,
  addStates,
  addTownships,
} from "@states/common/common.slice";
import { useGetAllStatesQuery } from "@states/common/common.api";
import useRegionEffect from "@hooks/distribution-region/useRegionEffect";
import TripDetailListTable from "@components/extra/trip/TripDetailListTable";
import RegionDetailTable from "@components/extra/distribution-region/RegionDetailTable";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import TripProductDetailTable from "@components/extra/trip/TripProductDetailTable";
import { useFieldArray, useForm } from "react-hook-form";
import useGetAllRegions from "@hooks/distribution-region/useGetAllRegions";
import useGetAllVehicle from "@hooks/trip/useGetAllVehicle";
import useGetAllOfficeUsers from "@hooks/user-management/useGetAllOfficeUsers";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";
import { formatDBDate } from "@utils/general/format-db-date";
import { tripApprovalSchema } from "@helpers/validation-schemas/trip/trip-approval.schema";

const TripApprovalEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const [leaving, setLeaving] = useState(false);
  const {
    cities,
    townships,
    // regionDetail,
    isLoading: regionLoading,
  } = useRegionEffect();
  const {
    register,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<any>();
  const { append, remove, update } = useFieldArray({
    control,
    name: "product_detail",
  });
  const { warehouseData } = useGetAllWarehouses({
    limit: 1000,
    warehouse_type: 2,
  });
  const { data: stateData } = useGetAllStatesQuery();
  const { data: tripData } = useGetTripQuery({
    id: id!,
  });

  const { regionData } = useGetAllRegions({ limit: 100 });
  const { vehicleData } = useGetAllVehicle({ limit: 1000 });
  const [updateTripApproval, { error: createError, isSuccess, isLoading }] =
    useUpdateTripApprovalMutation();
  const { officeUserData } = useGetAllOfficeUsers({ limit: 10000 });

  const {
    regions,
    ways,
    tripDetails,
    warehouses,
    states: storedStates,
    states,
    cities: storedCities,
    townships: storedTownships,
    regionDetails: regionDetail,
    vehicles,
    users,
    productDetails,
    formValues,
  } = useSelector((state: RootState) => state.common);
  useEffect(() => {
    //@ts-ignore
    if (tripData?.data) {
      dispatch(
        addAllocations(
          //@ts-ignore
          tripData?.data.trip_warehouse_details
        )
      );
      dispatch(
        addStates(
          //@ts-ignore
          tripData?.data.trip_details
        )
      );
      dispatch(
        addCities(
          //@ts-ignore
          tripData?.data.trip_details
        )
      );
      dispatch(
        addTownships(
          //@ts-ignore
          tripData?.data.trip_details
        )
      );
      dispatch(
        addAllocations(
          //@ts-ignore
          tripData?.data.trip_warehouse_details
        )
      );
      dispatch(
        addVehicles(
          //@ts-ignore
          tripData?.data.trip_vehicle
        )
      );
      dispatch(
        addUsers(
          //@ts-ignore
          tripData?.data.assignee_data
        )
      );

      if (tripData?.data.product_detail) {
        for (const pd of tripData?.data.product_detail) {
          append({
            amount: 0,
            product_id: pd.product_id,
            qty: pd.qty,
            unit_id: pd.unit_id,
            unit_name: pd.unit_name,
            product_code: pd.product_code,
            product_name: pd.product_name,
            product_category_id: pd.product_category_id,
            product_category_name: pd.product_category_name,
            pack_type_id: pd.pack_type_id,
            product_type_name: pd.product_type_name,
            api_level: pd.api_level,
            unit_type_data: pd.unit_type_data,
          });
        }
      }
    }
  }, [tripData]);

  const fields = useMemo(() => {
    return tripFields({
      data: {
        warehouses: warehouseData,
        states: stateData || [],
        cities: cities || [],
        townships: townships || [],
        regionData: regionData || [],
        vehicleData: vehicleData || [],
        officeUserData: officeUserData || [],
      },
      selectActions: {
        warehouseSelectionAction: addAllocations,
        stateSelectionAction: addStates,
        citySelectionAction: addCities,
        townshipSelectionAction: addTownships,
        vehicleSelectionAction: addVehicles,
        userSelectionAction: addUsers,
      },
      disabled: {
        cityDisabled: storedStates.length <= 0,
        townshipDisabled: storedCities.length <= 0,
      },
      selectedIds: {
        warehouseSelectedIds: warehouses.map((wh: any) => wh.warehouse_id),
        divisionSelectedIds: states?.map((s: any) => s.state_id),
        citySelectedIds: storedCities?.map((c: any) => c.city_id),
        townshipSelectedIds: storedTownships?.map((t: any) => t.township_id),
        vehicleSelectedIds: vehicles?.map((v: any) => v.vehicle_id),
        userSelectedIds: users?.map((v: any) => v.user_id),
        // warehouseSelectedIds: warehouses.map((wh: any) => wh.warehouse_id),
        // divisionSelectedIds: states?.map((s: any) => s.state_id),
        // citySelectedIds: storedCities?.map((c: any) => c.city_id),
        // townshipSelectedIds: storedTownships?.map((t: any) => t.township_id),
      },

      selectMultipleIds: {
        // warehouseSelectedIds: warehouses.map((wh: any) => wh.warehouse_id),
        // divisionSelectedIds: states?.map((s: any) => s.state_id),
        // citySelectedIds: storedCities?.map((c: any) => c.city_id),
        // townshipSelectedIds: storedTownships?.map((t: any) => t.township_id),
        // vehicleSelectedIds: vehicles?.map((v: any) => v.vehicle_id),
        // userSelectedIds: users?.map((v: any) => v.user_id),
      },
      values: {
        //@ts-ignore
        // warehousesValue: tripData?.data.trip_warehouse_details.map(
        //   (wh: any) => wh.warehouse_id
        // ),
        PhotoValue: tripData?.data.attachment,
        StatusValue: tripData?.data.status,
        ReasonValue: tripData?.data.reason,
      },
    });
  }, [
    tripData,
    stateData,
    states,
    users,
    vehicles,
    cities,
    townships,
    warehouseData,
    warehouses,
    storedStates,
    storedCities,
    storedTownships,
    regionData,
    vehicleData,
    officeUserData,
  ]);

  console.log("warehouses", warehouses);
  useErrorHandling(...castTypeArr<TFailResponse>([createError]));

  useEffect(() => {
    if (!createError) {
      dispatch(addAllocations([]));
      dispatch(addStates([]));
      dispatch(addCities([]));
      dispatch(addTownships([]));
    }
  }, [createError, dispatch]);
  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        // const rest = removeKeys(reqBody, 'region_id', 'way_id','warehouse_id','trip_warehouse_detail_id');
        const rest = removeKeys(
          reqBody,
          "region_id",
          "way_id",
          "state_id",
          "township_id",
          "city_id",
          "warehouse_id"
        );
        const {
          attachment,
          proposal_date,
          way_id,
          state_id,
          township_id,
          city_id,
          warehouse_id,
          user_id,
          vehicle_id,
          ...data
        } = formValues;
        console.log("formValues", formValues);

        const res = await updateTripApproval({
          id: id!,
          body: {
            status: formValues?.status,
            description: formValues?.description,
            reason: formValues?.reason,
          },
        });

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;

        if (success || isSuccess) {
          setLeaving(true);
          await Toast.fire({
            title: message,
            icon: "success",
          });

          navigate(TRIP_MANAGEMENT.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [
      updateTripApproval,
      isSuccess,
      Toast,
      navigate,
      regionDetail,
      warehouses,
      vehicles,
      users,
      getValues,
      formValues,
    ]
  );

  return (
    <PageWrapper>
      {leaving && <LeavingSpinner />}
      <CustomForm
        title={TRIP_MANAGEMENT.APPROVAL_EDIT_PAGE_TITLE}
        schema={tripApprovalSchema}
        fields={fields}
        asyncDispatcher={asyncDispatcher}
        customComponent={<TripDetailListTable regionDetail={regionDetail} />}
        defaultValues={tripData?.data}
        customComponent2={
          <TripProductDetailTable
            warehouses={warehouses}
            append={append}
            remove={remove}
            errors={errors}
            update={update}
            register={register}
            getValues={getValues}
            setError={setError}
            watch={watch}
            setValue={setValue}
            control={control}
            isDisabled={true}
          />
        }
        // customComponent={
        //     <RegionDetailTable regionDetail={regionDetail} />
        // }
      />
    </PageWrapper>
  );
};

export default TripApprovalEditPage;
