import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { CONSIGNMENT_PAYMENT_RECEIVE } from "@utils/constants/consignment/consignment-payment-receive.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const consignmentPaymentReceiveApi = createApi({
  reducerPath: "consignmentPaymentReceiveApi",
  tagTypes: ["ConsignmentPaymentReceive"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllConsignmentPaymentReceives: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
      }
    >({
      query: ({ page = 1, limit = 10, search }) => {
        const queryParams = {
          url: CONSIGNMENT_PAYMENT_RECEIVE.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        return queryParams;
      },
    }),
    createConsignmentPaymentReceive: builder.mutation({
      query: (body) => ({
        url: CONSIGNMENT_PAYMENT_RECEIVE.CREATE,
        method: "POST",
        body,
      }),
    }),
    deleteConsignmentPaymentReceive: builder.mutation({
      query: (id) => ({
        url: `${CONSIGNMENT_PAYMENT_RECEIVE.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
    getOneConsignmentPaymentReceive: builder.query<
      any,
      { consignment_payment_receive_id: string | number }
    >({
      query: ({ consignment_payment_receive_id }) => {
        return `${CONSIGNMENT_PAYMENT_RECEIVE.GET_ONE}${consignment_payment_receive_id}`;
      },
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllConsignmentPaymentReceivesQuery,
  useLazyGetOneConsignmentPaymentReceiveQuery,
  useCreateConsignmentPaymentReceiveMutation,
  useDeleteConsignmentPaymentReceiveMutation,
} = consignmentPaymentReceiveApi;
