export const MARKETING_PROMOTION_INVOICE = {
  BASE_PATH: "/marketing-promotion/invoices/",
  MAKE_PAYMENT_RECEIVE_PATH(id: string | number) {
    return this.BASE_PATH + `${id}` + "/edit/make-payment-receive";
  },
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  ADD_BUTTON_TITLE: "Add New Invoice",
  LIST_PAGE_TITLE: "Invoice List",
  EDIT_PAGE_TITLE: "Edit Invoice",
  GET_ALL: "marketing-promotion-plan/invoices",
  GET_ONE: "marketing-promotion-plan/invoices",
  CREATE: "marketing-promotion-plan/invoice",
  UPDATE: "marketing-promotion-plan/invoices/update/",
  DELETE: "marketing-promotion-plan/invoices/delete/",
  ADD_NEW_TITLE: "Add New Secondary Sales Order Invoice",
};
