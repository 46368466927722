import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { VEHICLE_MANAGEMENT } from "@utils/constants/vehicle-management.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const vehicleApi = createApi({
  reducerPath: "vehicleApi",
  tagTypes: ["Vehicle"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllVehicle: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
      }
    >({
      query: ({ page = 1, limit = 10, search }) => {
        const queryParams = {
          url: VEHICLE_MANAGEMENT.GET_ALL,
          params: { page, limit },
        };

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        return queryParams;
      },
    }),
    getOneVehicle: builder.query<any, { id: string | number }>({
      query: ({ id }) => `${VEHICLE_MANAGEMENT.GET_ONE}${id}`,
    }),
    createVehicle: builder.mutation({
      query: (body) => ({
        url: VEHICLE_MANAGEMENT.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateVehicle: builder.mutation({
      query: ({ id, body }) => ({
        url: `${VEHICLE_MANAGEMENT.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    // approvePrimaryOrder: builder.mutation({
    //     query: ({ id, body }) => ({
    //         url: `${PRIMARY_SALE_ORDER.APPROVE}${id}`,
    //         method: 'PATCH',
    //         body,
    //     }),
    // }),
    deleteVehicle: builder.mutation({
      query: (id) => ({
        url: `${VEHICLE_MANAGEMENT.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllVehicleQuery,
  useGetOneVehicleQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,
} = vehicleApi;
