import { useGetAllRegionsQuery } from '@states/distribution-region/distribution-region.api';
import { TRegion } from '@type-defs/distribution-region/TRegion';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';

const useGetAllRegions = (args: {
    page?: number;
    limit?: number;
    region_name?: string;
}) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch,
        isFetching,
    } = useGetAllRegionsQuery({ ...args });

    const regionResponse = rawData as any as TSuccessResponse<TRegion>;

    return {
        regionData: regionResponse?.data,
        regionMessage: regionResponse?.message,
        regionSuccess: regionResponse?.success,
        regionPaginator: regionResponse?.paginator,
        regionError: error,
        regionLoading: isLoading,
        regionRefetch: refetch,
        regionFetching: isFetching,
    };
};

export default useGetAllRegions;
