import { z } from "zod";
export const tripSchema = z.object({
  trip_name: z
    .string({
      message: "Trip name is required",
    })
    .nonempty({
      message: "Trip name is required",
    }),
  proposal_date: z.union([
    z.string({
      message: "Proposal Date is required",
    }),
    z.date({
      message: "Proposal date must be a valid date",
    }),
  ]),
  warehouse_id: z.union(
    [
      z
        .string({
          message: "Allocation Account Name is required",
        })
        .nonempty({
          message: "Allocation Account Name is required",
        }),
      z.number({
        message: "Allocation Account Name is required",
      }),
    ],
    {
      message: "Allocation Account Name is required",
    }
  ),
  user_id: z.union(
    [
      z
        .string({
          message: "Assignee  Name is required",
        })
        .nonempty({
          message: "Assignee  Name is required",
        }),
      z.number({
        message: "Assignee  Name is required",
      }),
    ],
    {
      message: "Assignee  Name is required",
    }
  ),
  vehicle_id: z.union(
    [
      z
        .string({
          message: "Vehicle Name is required",
        })
        .nonempty({
          message: "Vehicle Name is required",
        }),
      z.number({
        message: "Vehicle Name is required",
      }),
    ],
    {
      message: "Vehicle Name is required",
    }
  ),
  region_id: z.union(
    [
      z
        .string({
          message: "Distribution Region Name is required",
        })
        .nonempty({
          message: "Distribution Region Name is required",
        }),
      z.number({
        message: "Distribution Region Name is required",
      }),
    ],
    {
      message: "Distribution Region Name is required",
    }
  ),
  state_id: z.union(
    [
      z
        .string({
          message: " State/Division Name  is required",
        })
        .nonempty({
          message: " State/Division Name is required",
        }),
      z.number({
        message: " State/Division Name is required",
      }),
    ],
    {
      message: " State/Division Name is required",
    }
  ),
  city_id: z.union(
    [
      z
        .string({
          message: " City Name  is required",
        })
        .nonempty({
          message: " City Name is required",
        }),
      z.number({
        message: " City Name is required",
      }),
    ],
    {
      message: " City Name Name is required",
    }
  ),
  township_id: z.union(
    [
      z
        .string({
          message: " Township Name  is required",
        })
        .nonempty({
          message: " Township Name is required",
        }),
      z.number({
        message: " Township Name is required",
      }),
    ],
    {
      message: " City Name Name is required",
    }
  ),

  //   region_id: z.union([
  //     z.string({
  //       message: "Region name is required",
  //     }),
  //     z.number().optional().nullable(),
  //   ]),
  way_id: z.union([
    z.string({
      message: "Way name is required",
    }),
    z.number().optional().nullable(),
  ]),
  description: z.string().optional().nullable(),
});

export type ZTrip = z.infer<typeof tripSchema>;

export interface TTrip extends ZTrip {
  trip_id: string | number;
  trip_code: string;
  region_name: string;
  warehouse_name: string;
}
