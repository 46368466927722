import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { MARKETING_GOODS_REQUESITION } from "@utils/constants/marketing-promotion-good-requisition.constants";

export const marketingGoodRequisitionApi = createApi({
  reducerPath: "marketingGoodRequisitionApi",
  tagTypes: ["MarketingGoodRequisitionGoodRequisition"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllMarketingGoodRequisitions: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        name?: string;
        marketing_promotion_invoice_code?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        marketing_promotion_invoice_code,
        name,
      }) => {
        const queryParams = {
          url: MARKETING_GOODS_REQUESITION.GET_ALL,
          params: { page, limit },
        };

        if (marketing_promotion_invoice_code) {
          //@ts-ignore
          queryParams.params.marketing_promotion_invoice_code =
            marketing_promotion_invoice_code;
        }

        if (name) {
          //@ts-ignore
          queryParams.params.name = name;
        }

        return queryParams;
      },
    }),
    getMarketingGoodRequisition: builder.query<any, { id: string | number }>({
      query: ({ id }) => {
        return `${MARKETING_GOODS_REQUESITION.GET_ONE}${id}`;
      },
    }),
    createMarketingGoodRequisition: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_GOODS_REQUESITION.CREATE}`,
        method: "POST",
        body,
      }),
    }),
    updateMarketingGoodRequisition: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_GOODS_REQUESITION.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteMarketingGoodRequisition: builder.mutation({
      query: (id) => ({
        url: `${MARKETING_GOODS_REQUESITION.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllMarketingGoodRequisitionsQuery,
  useGetMarketingGoodRequisitionQuery,
  useCreateMarketingGoodRequisitionMutation,
  useUpdateMarketingGoodRequisitionMutation,
  useDeleteMarketingGoodRequisitionMutation,
} = marketingGoodRequisitionApi;
