export const TRIP_MANAGEMENT = {
  BASE_PATH: "/trip/trip-proposal/",
  APPROVAL_BASE_PATH: "/trip/trip-proposal-approval/",
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  ADD_BUTTON_TITLE: "Add New Trip Proposal",
  LIST_PAGE_TITLE: "Trip Proposal List",
  APPROVAL_LIST_PAGE_TITLE: "Trip Proposal Approval List",
  EDIT_PAGE_TITLE: "Edit Trip Proposal",
  APPROVAL_EDIT_PAGE_TITLE: "Edit Trip Proposal Approval",
  GET_ALL: "/trip",
  GET_ONE: "/trip/edit/",
  APPROVAL_GET_ONE: "/trip/edit/",
  CREATE: "/trip/create",
  UPDATE: "/trip/update/",
  APPROVAL_UPDATE: "/trip/approval/update/",
  DELETE: "/trip/delete/",
  ADD_NEW_TITLE: "Add New Trip Proposal",
};
