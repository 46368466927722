import React, { useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import PageWrapper from "@components/layouts/PageWrapper";
import Search from "@components/layouts/Search";
import { useLocation, useNavigate } from "react-router-dom";
import usePagination from "@hooks/usePagination";
import useSearch from "@hooks/useSearch";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { DeletePopup } from "@helpers/popups/DeletePopup";
import TableSkeleton from "@components/layouts/TableSkeleton";
import { TDeleteSuccesResponse } from "@type-defs/general/TDeleteSuccessResponse";
import { Toast } from "@helpers/popups/Toast";

import { CONSIGNMENT } from "@utils/constants/consignment/consignment.constants";

import CollaspseIcon from "@components/icons/CollaspseIcon";
import ExpandIcon from "@components/icons/ExpandIcon";
import NoRecord from "@components/layouts/NoRecord";
import OutlinedStatus from "@components/status/OutlinedStatus";
import { consignmenttColumns } from "@helpers/columns/consignment/consignment.columns";
import {
  useGetAllConsignmentQuery,
  useDeleteConsignmentMutation,
} from "@states/consignment/consignment.api";
import SalesOrderFilter from "@components/filters/SalesOrderFilter";
import { formatDate } from "@utils/general/format-date";
import InvoiceDetailModal from "@components/modals/consignment/InvoiceDetailModal";
import { useLazyGetConsignmentInvoiceQuery } from "@states/consignment/consignment-invoice.api";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { useLazyGetOneConsignmentPaymentReceiveQuery } from "@states/consignment/consignment-payment-receive.api";
import PaymentReceiveModal from "@components/modals/consignment/PaymentReceiveModal";
import ConsignmentSalesOrderFilter from "@components/filters/ConsignmentSalesOrderFilter";
const ConsignmentPage = () => {
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();
  const { search, setSearch } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isInvoiceOpen, setIsInvoiceOpen] = React.useState(false);
  const [isPaymentReceiveOpen, setIsPaymentReceiveOpen] = React.useState(false);
  const [expandedRowIds, setExpandedRowIds] = React.useState<
    number[] | string[] | any[]
  >([]);
  const tarRef = useRef<any>(null);

  const filterRef = useRef({
    from_date: undefined,
    to_date: undefined,
    customer_id: undefined,
    status: undefined,
    payment_type_id: undefined,
    region_id: undefined,
  });
  const {
    data,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetAllConsignmentQuery({
    page,
    limit,
    search: search,
    ...filterRef.current,
  });

  const [deleteConsignment, { error: deleteError, isLoading }] =
    useDeleteConsignmentMutation();

  useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

  useEffect(() => {
    // Select all elements with the class 'my-class'
    const elements = document.getElementsByClassName("wtf");

    // Check if there are any elements and get the last one
    if (elements.length > 0) {
      const lastElement = elements[elements.length - 1];

      if (lastElement) {
        tarRef.current = lastElement;

        tarRef.current.classList.add("another-class");
      }
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      refetch();
    }
  }, [location.state?.reload]);

  const okayData = useMemo(
    () =>
      //@ts-ignore
      data?.data?.map((d, i) => ({
        ...d,
        no: i,
      })),
    [data]
  );

  const editHandler = (
    consignment_id: string | number,
    status: string | number
  ) => {
    navigate(`${CONSIGNMENT.BASE_PATH}${consignment_id}/edit`, {
      state: {
        status,
      },
    });
  };

  const deleteHandler = async (id: number | string) => {
    try {
      if (!id) {
        console.error("ID is undefined or null");
        return;
      }

      const { isConfirmed } = await DeletePopup.fire();

      if (isConfirmed) {
        const res = await deleteConsignment(id);

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TDeleteSuccesResponse;

        if (success) {
          Toast.fire({
            icon: "success",
            title: message,
          });

          refetch();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Detial Modal
  const [
    getConsignmentInvoice,
    {
      data: getConsignmentInvoiceData,
      isLoading: getConsignmentInvoiceLoading,
    },
  ] = useLazyGetConsignmentInvoiceQuery();
  const handleInvoiceModalOpen = (id: string | number) => {
    getConsignmentInvoice({
      consignment_invoice_id: id,
    }).then((res) => {
      if (res.data) {
        setIsInvoiceOpen(true);
      }
    });
  };
  const [
    getOneConsignmentPaymentReceive,
    {
      data: getOneConsignmentPaymentReceiveData,
      isLoading: getOneConsignmentPaymentReceiveLoading,
    },
  ] = useLazyGetOneConsignmentPaymentReceiveQuery();

  const handlePaymentReceiveModalOpen = (id: string | number) => {
    getOneConsignmentPaymentReceive({
      consignment_payment_receive_id: id,
    }).then((res) => {
      if (res.data) {
        setIsPaymentReceiveOpen(true);
      }
    });
  };
  console.log(okayData, "xxxx");

  return (
    <React.Fragment>
      {(getConsignmentInvoiceLoading ||
        getOneConsignmentPaymentReceiveLoading) && <LoadingSpinner />}
      <Search setSearch={setSearch} />
      <PageWrapper
        addPath={CONSIGNMENT.ADD_PATH()}
        addButtonTitle={CONSIGNMENT.ADD_BUTTON_TITLE}
        title={CONSIGNMENT.LIST_PAGE_TITLE}
      >
        <ConsignmentSalesOrderFilter refetch={refetch} filterRef={filterRef} />
        <InvoiceDetailModal
          //@ts-ignore
          data={getConsignmentInvoiceData?.data || {}}
          isOpen={isInvoiceOpen}
          setIsOpen={setIsInvoiceOpen}
        />
        <PaymentReceiveModal
          //@ts-ignore
          data={getOneConsignmentPaymentReceiveData?.data || {}}
          isOpen={isPaymentReceiveOpen}
          setIsOpen={setIsPaymentReceiveOpen}
        />
        <DataTable
          keyField="consignment_id"
          responsive
          striped
          pagination
          paginationServer
          //@ts-ignore
          paginationTotalRows={data?.data.paginator?.totalItems}
          progressPending={isFetching}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={<NoRecord />}
          progressComponent={<TableSkeleton row={10} col={7} />}
          //@ts-ignore
          columns={consignmenttColumns(editHandler, deleteHandler)}
          customStyles={{
            rows: {
              style: {
                minWidth: "200px",
              },
            },
            // header: {
            //   style: {
            //     minWidth: "200px",
            //   },
            // },
          }}
          //@ts-ignore
          data={[
            ...(okayData ?? []),
            {
              no: "Totals:",
              grand_total_amount:
                (okayData &&
                  okayData[0] &&
                  okayData?.[0]["grand_total_sum"].toFixed(2)) ||
                0,
              noAction: true,
            },
          ]}
          // data={data?.data}
          conditionalRowStyles={[
            {
              when: (row) => expandedRowIds.includes(row.consignment_id),
              style: {
                backgroundColor: "#D3E4F8",
                // color: 'white',
                "&:hover": {
                  cursor: "pointer",
                },
              },
            },
            {
              when: (row) => row.no === "Totals:",
              style: {
                backgroundColor: "#F1F2F5",
                fontWeight: 500,
              },
            },
          ]}
          onRowExpandToggled={(expanded, row) => {
            setExpandedRowIds((prev) => {
              if (expanded && !prev.includes(row.consignment_id)) {
                return [...prev, row.consignment_id];
              } else {
                return prev.filter((id) => id !== row.consignment_id);
              }
            });
            return <div>A</div>;
          }}
          expandableRows
          expandableRowDisabled={(row) =>
            (row.invoice_data == null &&
              row.payment_receive_data == null &&
              row.order_approval_data == null &&
              row.goods_request_note_data == null &&
              row.goods_issue_note_data == null) ||
            row.no == "Totals:"
          }
          expandableInheritConditionalStyles
          expandableIcon={{
            collapsed: <CollaspseIcon />,
            expanded: <ExpandIcon />,
          }}
          expandableRowsComponent={(row) => {
            console.log(row);
            return (
              <>
                {/* Invoices */}
                {/* @ts-ignore */}
                {row.data?.invoice_data?.map(
                  //   @ts-ignore
                  (invoice, index) => (
                    <div className="grid border-[0.1px] border-b-gray-200 w-full h-full pl-[8%] min-w-full grid-cols-6 p-[0.59rem] text-primary-dark bg-gray-100">
                      <div className="flex items-center justify-center">
                        {index + 1}
                      </div>
                      <div className="flex items-center font-semibold">
                        Invoice
                      </div>
                      <div
                        onClick={() =>
                          handleInvoiceModalOpen(invoice.consignment_invoice_id)
                        }
                        className="flex items-center italic font-semibold underline cursor-pointer"
                      >
                        {invoice.consignment_invoice_code}
                      </div>
                      <div className="flex items-center">
                        {formatDate(new Date(invoice.invoice_date))}
                      </div>
                      <div className="flex items-center">
                        <OutlinedStatus
                          width={"w-fit min-w-[11ch]"}
                          title={invoice.status}
                          color={
                            invoice.status === "Approve & Open"
                              ? "blue"
                              : invoice.status === "Open"
                              ? "green"
                              : "gray"
                          }
                        />
                      </div>
                    </div>
                  )
                )}
                {/* Payment Receive */}
                {/* @ts-ignore */}
                {row.data?.payment_receive_data?.map(
                  //   @ts-ignore
                  (paymentReceive, index) => (
                    <div className="grid w-full h-full pl-[8%] min-w-full grid-cols-6 p-[0.89rem] text-primary-dark bg-gray-100">
                      <div className="flex items-center justify-center">
                        {index + 1}
                      </div>
                      <div className="flex items-center font-semibold">
                        Payment Receive
                      </div>
                      <div
                        onClick={() =>
                          handlePaymentReceiveModalOpen(
                            paymentReceive.consignment_payment_receive_id
                          )
                        }
                        className="flex items-center italic font-semibold underline cursor-pointer"
                      >
                        {paymentReceive.payment_receive_code}
                      </div>
                      <div className="flex items-center">
                        {/* {
                                                    paymentReceive.payment_receive_date
                                                } */}
                        {formatDate(
                          new Date(paymentReceive.payment_receive_date)
                        )}
                      </div>
                      <div className="flex items-center">-</div>
                    </div>
                  )
                )}
              </>
            );
          }}
        />
      </PageWrapper>
    </React.Fragment>
  );
};

export default ConsignmentPage;
