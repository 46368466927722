import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import PageWrapper from "@components/layouts/PageWrapper";
import Search from "@components/layouts/Search";
import { useLocation, useNavigate } from "react-router-dom";
import usePagination from "@hooks/usePagination";
import useSearch from "@hooks/useSearch";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { useDidMountEffect } from "@hooks/useDidMountEffect";
import { DeletePopup } from "@helpers/popups/DeletePopup";
import TableSkeleton from "@components/layouts/TableSkeleton";
import { TDeleteSuccesResponse } from "@type-defs/general/TDeleteSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import {
  useDeletePrimaryPaymentReceiveMutation,
  useGetAllPrimaryPaymentReceivesQuery,
} from "@states/primary-sale-order/primary-payment-receive.api";
import {
  consignmentPaymentReceiveApi,
  useGetAllConsignmentPaymentReceivesQuery,
  useDeleteConsignmentPaymentReceiveMutation,
  useLazyGetOneConsignmentPaymentReceiveQuery,
} from "@states/consignment/consignment-payment-receive.api";
import { paymentReceiveColumns } from "@helpers/columns/primary-sale-order/payment-receive.columns";
import { consignmentReceiveColumns } from "@helpers/columns/consignment/payment-receive.columns";
import PaymentReceiveModal from "@components/modals/consignment/PaymentReceiveModal";
const ConsignmentPaymentReceivePage = () => {
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();
  const { search, setSearch } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPaymentReceiveOpen, setIsPaymentReceiveOpen] = React.useState(false);

  const {
    data,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetAllConsignmentPaymentReceivesQuery({
    page,
    limit,
    search,
  });
  const [
    getOneConsignmentPaymentReceive,
    {
      data: getOneConsignmentPaymentReceiveData,
      isLoading: getOneConsignmentPaymentReceiveLoading,
    },
  ] = useLazyGetOneConsignmentPaymentReceiveQuery();

  const [deleteConsignmentPaymentReceive, { error: deleteError, isLoading }] =
    useDeleteConsignmentPaymentReceiveMutation();

  // a custom hook that will handle fetch/mutation errors
  useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      refetch();
    }
  }, [location.state?.reload]);
  const handlePaymentReceiveModalOpen = (id: string | number) => {
    getOneConsignmentPaymentReceive({
      consignment_payment_receive_id: id,
    }).then((res) => {
      if (res.data) {
        setIsPaymentReceiveOpen(true);
      }
    });
  };
  const deleteHandler = async (id: number | string) => {
    try {
      const { isConfirmed } = await DeletePopup.fire();

      if (isConfirmed) {
        const res = await deleteConsignmentPaymentReceive(id);

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TDeleteSuccesResponse;

        if (success) {
          Toast.fire({
            icon: "success",
            title: message,
          });

          refetch();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Search setSearch={setSearch} />
      <PageWrapper title="Payment Receive List">
        <PaymentReceiveModal
          //@ts-ignore
          data={getOneConsignmentPaymentReceiveData?.data || {}}
          isOpen={isPaymentReceiveOpen}
          setIsOpen={setIsPaymentReceiveOpen}
        />
        <DataTable
          keyField="product_tag_id"
          responsive
          striped
          pagination
          paginationServer
          //@ts-ignore
          paginationTotalRows={data?.paginator?.totalItems}
          progressPending={isFetching}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={<TableSkeleton row={10} col={3} />}
          progressComponent={<TableSkeleton row={10} col={3} />}
          //@ts-ignore
          columns={consignmentReceiveColumns(
            deleteHandler,
            handlePaymentReceiveModalOpen
          )}
          //@ts-ignore
          data={data?.data}
        />
      </PageWrapper>
    </React.Fragment>
  );
};

export default ConsignmentPaymentReceivePage;
