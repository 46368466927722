import { z } from "zod";
export const tripApprovalSchema = z
  .object({
    status: z.union(
      [
        z
          .string({
            message: "Status is required",
          })
          .nonempty({
            message: "Status is required",
          }),
        z.number({
          message: "Status is required",
        }),
      ],
      {
        message: "Status is required",
      }
    ),
    reason: z
      .string({
        message: "Reason is required",
      })
      .nonempty({
        message: "Reason is required",
      }),
    description: z.string().optional().nullable(),
  })
  .superRefine((val: any, ctx: any) => {
    if (val.status == 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_arguments,
        path: ["status"],
        message: "Status is required",
        fatal: true,
      });
      return z.NEVER;
    }
  });

export type ZTrip = z.infer<typeof tripApprovalSchema>;

export interface TTrip extends ZTrip {
  trip_id: string | number;
  trip_code: string;
  region_name: string;
  warehouse_name: string;
}
