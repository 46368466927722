import PageWrapper from "@components/layouts/PageWrapper";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useGetAllWarehouses from "@hooks/warehouse-and-inventory/useGetAllWarehouses";

import { z } from "zod";
import BackButton from "@components/buttons/BackButton";
import CustomSearchModalChooseField from "@components/form/CustomSearchModalChooseField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";
import {
  addFormValues,
  addProductDetail,
  removeProductDetail,
  replaceProductDetail,
  resetFormValues,
  updateProductDetail,
} from "@states/common/common.slice";
import CustomButton from "@components/buttons/CustomButton";
import CustomDateField from "@components/form/CustomDateField";
import CustomSelectField from "@components/form/CustomSelectField";
import {
  consignmentproductDetailColumns,
  consignmentproductDetailColumnNames,
} from "@helpers/columns/consignment/consignment-detail.columns";
import CustomInputField from "@components/form/CustomInputField";
import { thousandSeperator } from "@utils/general/digit-separators";
import { CONSIGNMENT } from "@utils/constants/consignment/consignment.constants";
import TableSkeleton from "@components/layouts/TableSkeleton";
import TableEmpty from "@components/layouts/TableEmpty";

import {
  useGetConsignmentContractCustomerQuery,
  useGetConsignmentContractProductDetailQuery,
  useLazyGetConsignmentContractProductDetailQuery,
  useCreateConsignmentMutation,
  useUpdateConsignmentMutation,
  useLazyGetConsignmentContractListByBuQuery,
} from "@states/consignment/consignment.api";
import useGetOneConsignment from "@hooks/consignment/useGetOneConsignment";

import { consignmentSchema } from "@helpers/validation-schemas/consignment/consignment.scehma";
import { useGetAllPaymentTermsQuery } from "@states/common/common.api";
import { formatDBDate } from "@utils/general/format-db-date";
import useGetAllRegions from "@hooks/distribution-region/useGetAllRegions";

function truncateDecimal(input: any, decimalPlaces: number = 2): number {
  let number = parseFloat(input);
  if (isNaN(number)) {
    return 0;
  }

  if (Number.isInteger(number)) {
    return number;
  }
  return parseFloat(number.toFixed(decimalPlaces));
}
type FormFields = z.infer<typeof consignmentSchema>;

const ConsignmentEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const {
    state: { status },
  } = useLocation();
  const [businessUnits, setBusinessUnits] = useState<any>([]);

  const { formValues } = useSelector((state: RootState) => state.common);
  const { consignmentData, consignmentRefetch } = useGetOneConsignment(id!);
  const { data: customerData } = useGetConsignmentContractCustomerQuery({
    page: 1,
    limit: 100,
  });
  console.log("C<<<", consignmentData);
  const [
    updateConsignment,
    { isLoading, error: updateError, isSuccess, data },
  ] = useUpdateConsignmentMutation();
  const [
    getConsignmentContractListByBu,
    { data: contractListByBuData, isLoading: contractListByBuDataLoading },
  ] = useLazyGetConsignmentContractListByBuQuery();

  const { data: paymentTermData } = useGetAllPaymentTermsQuery();
  useEffect(() => {
    if (formValues?.business_unit_id) {
      getConsignmentContractListByBu({
        business_unit_id: formValues?.business_unit_id,
      });
    }
  }, [formValues?.business_unit_id]);
  let defaultValues = {
    consignment_contract_id: consignmentData?.consignment_contract_id,
    sales_date: consignmentData?.sales_date,
    customer_id: consignmentData?.customer_id,
    payment_type_id: consignmentData?.payment_type_id,
    payment_term_id: consignmentData?.payment_term_id,
    description: consignmentData?.description,
    region_id: consignmentData?.region_id,
    payment_terms: consignmentData?.payment_terms,
    business_unit_id: consignmentData?.business_unit_id,
    business_unit_name: consignmentData?.business_unit_name,
    customer_name: consignmentData?.customer_first_name,
    product_detail: consignmentData?.products,
    sub_total: consignmentData?.sub_total,
    grand_total_amount: consignmentData?.grand_total_amount,
    discount_amount: consignmentData?.discount_amount,
    normal_discount: consignmentData?.normal_discount,
    normal_discount_type: consignmentData?.normal_discount_type,
    // discount: consignmentData?.discount_amount,
    tax_amount: consignmentData?.tax_amount,
    other_charges: consignmentData?.other_charges,
    discount_type: consignmentData?.discount_type,
    tax_type: consignmentData?.tax_type,
    tax: consignmentData?.tax,
    discount: consignmentData?.discount,
    status: consignmentData?.status,
  };
  console.log("defaultValues", defaultValues);
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<FormFields>({
    defaultValues,
    resolver: zodResolver(consignmentSchema),
  });
  const { warehouseData } = useGetAllWarehouses({});
  const {
    regionData,
    regionError,
    regionRefetch,
    regionFetching,
    regionPaginator,
  } = useGetAllRegions({
    limit: 50,
  });

  // const [createConsignment, { isLoading, isSuccess, error: createError }] =
  //     useCreateConsignmentMutation();

  const [getProductDetail, { data: productData, isLoading: productLoading }] =
    useLazyGetConsignmentContractProductDetailQuery();

  const productBarcodeRef = useRef(null);

  const { append, remove, update, replace } = useFieldArray({
    control,
    name: "product_detail",
  });

  // useErrorHandling(...castTypeArr<TFailResponse>([createError]));
  useEffect(() => {
    if (formValues?.customer_id && customerData?.data) {
      const customer = customerData?.data.find(
        (c: any) => c.customer_id == formValues?.customer_id
      );

      if (customer?.business_units && customer.business_units.length > 0) {
        setBusinessUnits(customer?.business_units || []);
      }
    }
  }, [formValues?.customer_id, customerData]);

  useEffect(() => {
    if (consignmentData) {
      const pd = defaultValues?.product_detail?.map((dtl: any) => ({
        product_id: dtl.product_id,
        product_code: dtl.product_code,
        product_name: dtl.product_name,
        unit_name: dtl.unit_name,
        unit_id: dtl.unit_id,
        sales_price: dtl.sales_price,
        qty: dtl.qty,
        amount: dtl.amount,
        discount_type: dtl.discount_type,
        discount_amount: dtl.discount_amount,
        normal_discount: dtl.normal_discount,
        normal_discount_type: dtl.normal_discount_type,
        unit_type_data: dtl.unit_type_data,
        tax_type: dtl.tax_type,
        tax_amount: dtl.tax_amount,
        total_amount: dtl.total_amount,
        discount: dtl.discount,
        tax: dtl.tax || dtl.secondary_sales_tax,
        warehouse_name: dtl.warehouse_name,
        contract_qty: dtl.consignment_contract_qty,
        warehouse_id: dtl.warehouse_id,
        status: status,
        // discount:
        //     dtl?.discount_type !== 'AMT'
        //         ? calculatePercentage(
        //               dtl?.total_amount,
        //               dtl?.discount_amount
        //           )
        //         : dtl?.discount_amount,
        // tax:
        //     dtl?.tax_type !== 'AMT'
        //         ? calculatePercentage(dtl?.total_amount, dtl?.tax_amount)
        //         : dtl?.tax_amount,
      }));

      dispatch(addFormValues({ ...defaultValues, product_detail: pd }));

      reset({ ...defaultValues, product_detail: pd });
    }
  }, [consignmentData, reset, dispatch]);

  useEffect(() => {
    if (id) {
      consignmentRefetch();
    }
  }, [id, consignmentRefetch]);

  // useEffect(() => {
  //     if (
  //         productData &&
  //         productData?.data.consignment_contract_details.length > 0
  //     ) {
  //         const productDetail =
  //             productData?.data?.consignment_contract_details.map(
  //                 (product: any) => ({
  //                     product_id: product.product_id,
  //                     unit_id: product.unit_id,
  //                     sales_price: product.secondary_sales_price,
  //                     qty: 0,
  //                     total_amount: 0 * product.secondary_sales_price,
  //                     product_code: product.product_code,
  //                     product_name: product.product_name,
  //                     unit_name: product.unit_name,
  //                     amount: 0 * product.secondary_sales_price,
  //                     discount: 0,
  //                     discount_amount: 0,
  //                     discount_type: 'AMT',
  //                     tax: product.secondary_sales_tax,
  //                     tax_amount: 0,
  //                     tax_type: product.secondary_sales_tax_type,
  //                 })
  //             );
  //         //@ts-ignore
  //         replace(productDetail);
  //         dispatch(replaceProductDetail(productDetail));
  //     }
  // }, [productData]);

  //   useEffect(() => {
  //     if (formValues?.customer_id && customerData?.data) {
  //       const customer = customerData?.data.find(
  //         (c: any) => c.customer_id == formValues?.customer_id
  //       );

  //       if (customer?.business_units && customer.business_units.length > 0) {
  //         setBusinessUnits(customer?.business_units || []);
  //       }
  //     }
  //   }, [formValues?.customer_id, customerData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const { product_detail, ...theRest } = value;

      const targetIndex = Number(name?.split(".")?.[1]);
      const targetProductDetail: any = product_detail![targetIndex!];

      if (targetProductDetail && product_detail) {
        if (targetProductDetail) {
          let qty = targetProductDetail.qty;

          if (qty > targetProductDetail.contract_qty) {
            qty = targetProductDetail.contract_qty;
          }
          let tax = isNaN(targetProductDetail.tax)
            ? 0
            : Number(targetProductDetail.tax);
          let discount = isNaN(targetProductDetail.discount)
            ? 0
            : Number(targetProductDetail.discount);
          const updatedProductAmount = targetProductDetail.sales_price * qty;

          const updatedProductDiscount =
            targetProductDetail.discount_type === "%"
              ? updatedProductAmount * (discount / 100)
              : discount;

          const updatedProductTax =
            targetProductDetail.tax_type === "%"
              ? updatedProductAmount * (tax / 100)
              : tax;

          const updatedTotalAmount =
            updatedProductAmount + updatedProductTax - updatedProductDiscount;

          update(targetIndex, {
            ...targetProductDetail,
            discount_amount: truncateDecimal(updatedProductDiscount),
            tax_amount: truncateDecimal(updatedProductTax),
            // amount
            amount: truncateDecimal(updatedProductAmount),
            // total amount
            total_amount: truncateDecimal(updatedTotalAmount),
          });
          // TO DO: check whether this is actaully necessary
          updateProductDetail({
            index: targetIndex,
            product_detail: {
              ...targetProductDetail,
              discount_amount: truncateDecimal(updatedProductDiscount),
              tax_amount: truncateDecimal(updatedProductTax),
              amount: truncateDecimal(updatedProductAmount),
              total_amount: truncateDecimal(updatedTotalAmount),
            },
          });
        }
      }

      // Calculate sub_total
      const subTotal =
        product_detail?.reduce((acc, cur) => {
          if (cur) {
            return acc + (cur.total_amount || 0);
          }
          return acc;
        }, 0) || 0;

      // Calculate discount_amount based on sub_total
      const discountAmount =
        theRest.discount_type === "%"
          ? subTotal * (theRest.discount! / 100)
          : theRest.discount || 0;

      // Calculate discount_amount based on sub_total
      const taxAmount =
        theRest.tax_type === "%"
          ? subTotal * (theRest.tax! / 100)
          : theRest.tax || 0;

      const otherCharges = theRest.other_charges || 0;

      const grandTotalAmount = truncateDecimal(
        subTotal + otherCharges + taxAmount - discountAmount
      );

      //@ts-ignore
      dispatch(
        addFormValues({
          ...theRest,
          // @ts-ignore
          product_detail: product_detail?.map((pd) => ({ ...pd })),
          sub_total: subTotal,
          discount_amount: truncateDecimal(discountAmount),
          discount: theRest.discount || 0,
          tax_amount: truncateDecimal(taxAmount),
          tax: theRest.tax || 0,
          other_charges: otherCharges,
          grand_total_amount: grandTotalAmount,
          //@ts-ignore
          // sales_date: new Date(theRest.sales_date).toISOString(),
        })
      );
    });

    return () => {
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch]);

  const handleRemove = (index: number) => {
    remove(index);
    dispatch(removeProductDetail(index));
  };
  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      // async stuff here
      // console.log(data, 'data');
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      Toast.fire({
        title: data.message,
        icon: "success",
      });

      navigate(CONSIGNMENT.BASE_PATH, {
        state: {
          reload: true,
        },
      });
    }
  }, [isSuccess, data, navigate, Toast, updateError]);
  useEffect(() => {
    if (formValues.consignment_contract_id) {
      setValue("consignment_contract_id", formValues.consignment_contract_id);
      //   setValue("customer_id", customer_id);
      getProductDetail({
        consignment_contract_id: formValues.consignment_contract_id,
      });
    }
  }, [formValues.consignment_contract_id]);

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const { product_detail, discount, tax, ...rest } = formValues;

        const trimmedValues = {
          sales_date: formatDBDate(rest?.sales_date),
          customer_id: formValues.customer_id,
          region_id: formValues.region_id,
          consignment_contract_id: formValues.consignment_contract_id,
          payment_type_id: formValues.payment_type_id,
          payment_term_id: formValues.payment_term_id,
          business_unit_id: formValues.business_unit_id,
          remark: formValues.remark,
          description: formValues.description,
          tax_type: formValues.tax_type,
          tax_amount: formValues.tax_amount,
          discount_type: formValues.discount_type,
          discount_amount: formValues.discount_amount,
          other_charges: formValues.other_charges,
          grand_total_amount: formValues.grand_total_amount,
          sub_total: formValues.sub_total,
        };

        const formattedProductDetails = product_detail?.map((pd: any) => ({
          product_id: pd.product_id,
          unit_id: pd.unit_id,
          sales_price: pd.sales_price,
          qty: pd.qty,
          amount: pd.amount,
          normal_discount_type: pd.normal_discount_type,
          normal_discount: pd.normal_discount,
          discount_type: pd.discount_type,
          discount_amount: pd.discount_amount,
          tax_type: pd.tax_type,
          tax_amount: pd.tax_amount,
          total_amount: pd.total_amount,
          warehouse_id: pd.warehouse_id,
          consignment_contract_qty: pd.contract_qty,
          discount: pd.discount,
          tax: pd.tax,
        }));

        const res = await updateConsignment({
          id: id!,
          body: {
            tax: formValues.tax,
            discount: formValues.discount,
            ...trimmedValues,
            products: formattedProductDetails,
          },
        });
        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;
        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });
          navigate(CONSIGNMENT.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [updateConsignment, isSuccess, Toast, navigate, formValues]
  );

  return (
    <PageWrapper>
      {(isLoading || productLoading) && <LoadingSpinner />}
      <BackButton />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between mb-4">
          <h3 className="ml-2 text-2xl font-[600] text-primary-dark uppercase">
            Edit Consignemnt
          </h3>
          {status == 3 && (
            <CustomButton
              handleClick={() => {
                navigate(CONSIGNMENT.CONVERT_TO_INVOICE_PATH(id!), {
                  state: {
                    carriedFormValues: formValues,
                  },
                });
              }}
              color="bg-primary-dark"
              title="Convert To Invoice"
              className="px-8 py-4 text-lg font-semibold"
            />
          )}
        </div>
        <div className="grid grid-cols-6 gap-x-4 gap-y-8">
          <CustomDateField
            errors={errors}
            name="sales_date"
            label="Sale Date"
            register={register}
            key="sales_date"
            //@ts-ignore
            control={control}
            required
            // disabled={status == 3}
            disabled
          />
          <CustomSearchModalChooseField
            key={"customer_id"}
            // disabled={status == 3}
            disabled
            errors={errors}
            colSpan={2}
            name={"customer_id"}
            label="Customer Name"
            title={"Customer List"}
            columns={[
              {
                name: "customer_name",
                columnName: "Customer Name",
              },
              {
                name: "phone_number",
                columnName: "Phone Number",
              },
            ]}
            // @ts-ignore
            control={control}
            register={register}
            // borderColor={field.borderColor}
            placeHolder="Choose customer name"
            setValue={setValue}
            // @ts-ignore
            data={customerData?.data.map((customer) => ({
              customer_id: customer.customer_id,
              customer_name:
                customer.customer_first_name +
                " " +
                (customer.customer_last_name || ""),
              phone_number: customer.customer_phone1,
            }))}
            columnName={"Customer Name"}
            idName={"customer_id"}
            // value={field.value}
            nameName={"customer_name"}
            required
            value={defaultValues?.customer_name}
          />
          <CustomSelectField
            // disabled={status == 3 || getValues("customer_id") === undefined}
            disabled
            errors={errors}
            name="business_unit_id"
            label="Business Unit"
            placeHolder="Select business unit"
            //@ts-ignore
            control={control}
            options={businessUnits?.map((bu: any) => ({
              label: bu.business_unit_name,
              value: bu.business_unit_id,
            }))}
            required
          />
          <CustomSelectField
            errors={errors}
            // disabled={status == 3}
            disabled
            name="consignment_contract_id"
            label="Consignment Contract"
            placeHolder="Select Consignment Contract"
            //@ts-ignore
            control={control}
            //@ts-ignore
            options={contractListByBuData?.data?.map((item: any) => ({
              label: item.consignment_contract_code,
              value: item.consignment_contract_id,
            }))}
            required
          />

          <CustomSelectField
            errors={errors}
            name="region_id"
            label="Distribution Region"
            placeHolder="Select Distribution Region"
            //@ts-ignore
            control={control}
            //@ts-ignore
            options={regionData?.map((item: any) => ({
              label: item.region_name,
              value: item.region_id,
            }))}
            // disabled={status == 3}
            disabled
            required
          />
          <CustomSelectField
            errors={errors}
            name="payment_type_id"
            label="Payment Type"
            placeHolder="Select payment type"
            //@ts-ignore
            control={control}
            // disabled={status == 3}
            disabled
            options={[
              // {
              //     label: 'Credit',
              //     value: 1,
              // },
              { label: "Consignment", value: 2 },
              // { label: 'Cashdown', value: 3 },
            ]}
            required
          />
          <CustomSelectField
            errors={errors}
            name="payment_term_id"
            label="Payment Term"
            placeHolder="Select payment term"
            // disabled={status == 3}
            disabled
            //@ts-ignore
            control={control}
            options={paymentTermData
              ?.filter((item: any) => item.payment_terms == 3)
              .map((item: any) => ({
                label: item.payment_terms,
                value: item.payment_terms_id,
              }))}
            //@ts-ignore
            required
          />
          <CustomTextAreaField
            key={"description"}
            errors={errors}
            colSpan={6}
            name="description"
            register={register}
          />
          <div className="flex justify-between col-span-6">
            <h3 className="ml-2 font-[600] text-primary-dark">
              <span className=" text-[1.1rem]">Add Consignment Detail</span>
            </h3>
          </div>
          <div className="flex flex-col justify-between col-span-6">
            <h3 className="ml-2 font-[500] uppercase text-primary-dark">
              <span className=" text-[1.3rem]">Product Detail</span>
            </h3>
          </div>
          <div className="-mt-4 col-span-full">
            <DataTable
              className="pb-4"
              responsive
              striped
              //@ts-ignore
              columns={consignmentproductDetailColumns(
                errors,
                register,
                setValue,
                handleRemove,
                control
              )}
              //@ts-ignore
              data={formValues?.product_detail?.map((f, i) => ({
                ...f,
                index: i,
              }))}
              noDataComponent={
                <TableEmpty columnNames={consignmentproductDetailColumnNames} />
              }
            />

            {formValues?.product_detail?.length > 0 && (
              <div className="bg-zinc-100 rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                <div className="flex items-center mb-4">
                  <h5 className="text-[1.05rem] basis-3/12">Sub Total</h5>
                  <span className="s basis-1/12">:</span>
                  <div className="flex items-center basis-6/12"></div>
                  <h5 className="text-right basis-2/12 text-[1.05rem]">
                    {thousandSeperator(formValues.sub_total || 0)}
                  </h5>
                </div>
                <div className="flex items-center mb-2">
                  <h5 className=" basis-3/12 text-[1.05rem]">Discount</h5>
                  <span className=" basis-1/12">:</span>
                  <div className="flex items-center basis-6/12">
                    <CustomInputField
                      defaultValue={0}
                      classNames="-mr-3 w-[130px]"
                      noLabel
                      inputType="number"
                      errors={errors}
                      name={"discount"}
                      placeHolder=" "
                      register={register}
                      disabled={status === 3}
                    />
                    <select
                      {...register("discount_type")}
                      className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                      disabled={status === 3}
                    >
                      <option selected value="AMT">
                        AMT
                      </option>
                      <option value="%">%</option>
                    </select>
                  </div>
                  <h5 className="text-right basis-2/12 text-[1.05rem]">
                    -{thousandSeperator(formValues.discount_amount || 0)}
                  </h5>
                </div>
                <div className="flex items-center mb-2">
                  <h5 className=" basis-3/12 text-[1.05rem]">Tax</h5>
                  <span className=" basis-1/12">:</span>
                  <div className="flex items-center basis-6/12">
                    <CustomInputField
                      defaultValue={0}
                      classNames="-mr-3 w-[130px]"
                      noLabel
                      inputType="number"
                      errors={errors}
                      name={"tax"}
                      placeHolder=" "
                      disabled={status === 3}
                      register={register}
                    />
                    <select
                      {...register("tax_type")}
                      className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                      disabled={status === 3}
                    >
                      <option selected value="AMT">
                        AMT
                      </option>
                      <option value="%">%</option>
                    </select>
                  </div>
                  <h5 className="text-right basis-2/12 text-[1.05rem]">
                    {thousandSeperator(formValues.tax_amount || 0)}
                  </h5>
                </div>
                <div className="flex items-center mb-4">
                  <h5 className=" basis-3/12 text-[1.05rem]">Other Charges</h5>
                  <span className=" basis-1/12">:</span>
                  <div className="flex items-center basis-6/12">
                    <CustomInputField
                      defaultValue={0}
                      classNames="-mr-3 w-[200px]"
                      noLabel
                      inputType="number"
                      errors={errors}
                      name={"other_charges"}
                      placeHolder=" "
                      register={register}
                      disabled={status === 3}
                    />
                  </div>
                  <h5 className="text-right basis-2/12 text-[1.05rem]">
                    {thousandSeperator(formValues.other_charges || 0)}
                  </h5>
                </div>
                <div className="flex items-center mb-2">
                  <h5 className="text-xl font-semibold basis-3/12">
                    Grand Total
                  </h5>
                  <span className=" basis-1/12">:</span>
                  <div className="flex items-center basis-6/12"></div>
                  <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                    {thousandSeperator(formValues.grand_total_amount || 0)}
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="ml-2 mt-14">
          <CustomButton
            isLarge
            title="Cancel"
            color="white"
            textColor="gray.800"
            className="mr-10"
            handleClick={() => navigate(-1)}
          />
          <CustomButton
            handleClick={handleSubmit(onSubmit)}
            isLarge
            title={isSubmitting ? "Loading..." : "Submit"}
            disabled={isSubmitting}
            type="submit"
          />
        </div>
        {errors.root && (
          <div className="mt-6 text-red-500">{errors.root.message}</div>
        )}
      </form>
    </PageWrapper>
  );
};

export default ConsignmentEditPage;
