import { TTrip } from "@helpers/validation-schemas/trip/trip.schema";
import { useGetAllTripsQuery } from "@states/trip/trip.api";
import { useGetAllVehicleQuery } from "@states/trip/vehicle.api";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { TVehicle } from "@type-defs/vehicle/TVehicle";

const useGetAllVehicle = (args: {
  page?: number;
  limit?: number;
  region_name?: string;
}) => {
  const {
    data: rawData,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllVehicleQuery({ ...args });

  const vehicleResponse = rawData as any as TSuccessResponse<TVehicle>;

  return {
    vehicleData: vehicleResponse?.data,
    vehicleMessage: vehicleResponse?.message,
    vehicleSuccess: vehicleResponse?.success,
    vehiclePaginator: vehicleResponse?.paginator,
    vehicleError: error,
    vehicleLoading: isLoading,
    vehicleRefetch: refetch,
    vehicleFetching: isFetching,
  };
};

export default useGetAllVehicle;
