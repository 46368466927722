import { TTrip } from '@helpers/validation-schemas/trip/trip.schema';
import { useGetAllTripsQuery } from '@states/trip/trip.api';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';

const useGetAllTrips = (args: {
    page?: number;
    limit?: number;
    region_name?: string;
}) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch,
        isFetching,
    } = useGetAllTripsQuery({ ...args });

    const tripResponse = rawData as any as TSuccessResponse<TTrip>;

    return {
        tripData: tripResponse?.data,
        tripMessage: tripResponse?.message,
        tripSuccess: tripResponse?.success,
        tripPaginator: tripResponse?.paginator,
        tripError: error,
        tripLoading: isLoading,
        tripRefetch: refetch,
        tripFetching: isFetching,
    };
};

export default useGetAllTrips;
