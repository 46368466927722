import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { MARKETING_PAYMENT_RECEIVE } from "@utils/constants/marketing-promotion-payment.constants";

export const MarketingPaymentReceiveApi = createApi({
  reducerPath: "marketingPaymentReceiveApi",
  tagTypes: ["MarketingPaymentReceive"],
  baseQuery: baseQueryWithReauth,
  //   baseQuery: fetchBaseQuery({
  //     baseUrl: getBaseUrl(),
  //   }),
  endpoints: (builder) => ({
    getAllPaymentReceives: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        status?: number;
        from_date?: string;
        to_date?: string;
        code?: string;
      }
    >({
      query: ({ page = 1, limit = 10, status, from_date, to_date, code }) => {
        const queryParams = {
          url: MARKETING_PAYMENT_RECEIVE.GET_ALL,
          params: { page, limit },
        };

        if (code) {
          //@ts-ignore
          queryParams.params.code = code;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    createPaymentReceive: builder.mutation({
      query: (body) => ({
        url: MARKETING_PAYMENT_RECEIVE.CREATE,
        method: "POST",
        body,
      }),
    }),
    deletePaymentReceive: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PAYMENT_RECEIVE.DELETE}/${id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllPaymentReceivesQuery,
  useCreatePaymentReceiveMutation,
  useDeletePaymentReceiveMutation,
} = MarketingPaymentReceiveApi;
