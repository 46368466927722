import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { STOCK_TRANSFER } from "@utils/constants/warehouse-and-inventory/stock-transfer.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const stockTransferApi = createApi({
  reducerPath: "stockTransferApi",
  tagTypes: ["StockTransfer"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // getAllStockTransfers: builder.query<
    //     [],
    //     { page?: number; limit?: number }
    // >({
    //     query: ({ page = 1, limit = 10 }) => {
    //         const queryParams = {
    //             url: STOCK_TRANSFER.GET_ALL,
    //             params: { page, limit },
    //         };

    //         return queryParams;
    //     },
    // }),
    getAllStockTransfers: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
        search?: string;
        to_warehouse_id?: number;
        from_warehouse_id?: number;
        inventory_transfer_method?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        from_date,
        to_date,
        from_warehouse_id,
        to_warehouse_id,
        inventory_transfer_method,
        search,
      }) => {
        const queryParams = {
          url: STOCK_TRANSFER.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (from_warehouse_id) {
          //@ts-ignore
          queryParams.params.from_warehouse_id = from_warehouse_id;
        }
        if (to_warehouse_id) {
          //@ts-ignore
          queryParams.params.to_warehouse_id = to_warehouse_id;
        }
        if (inventory_transfer_method) {
          //@ts-ignore
          queryParams.params.inventory_transfer_method =
            inventory_transfer_method;
        }
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    getStockTransfer: builder.query<any, { id: string | number }>({
      query: ({ id }) => STOCK_TRANSFER.GET_ONE + id,
    }),
    createStockTransfer: builder.mutation({
      query: (body) => ({
        url: STOCK_TRANSFER.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateStockTransfer: builder.mutation({
      query: ({ id, body }) => ({
        url: `${STOCK_TRANSFER.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteStockTransfer: builder.mutation({
      query: (id) => ({
        url: `${STOCK_TRANSFER.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllStockTransfersQuery,
  useGetStockTransferQuery,
  useCreateStockTransferMutation,
  useUpdateStockTransferMutation,
  useDeleteStockTransferMutation,
} = stockTransferApi;
