export const MARKETING_GOODS_REQUESITION = {
  BASE_PATH: "/marketing-promotion/good-requisition/",
  MAKE_ISSUE_PATH(id: string | number) {
    return `${this.BASE_PATH}${id}/edit/make-invoice`;
  },
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  ADD_BUTTON_TITLE: "Add Goods Request Note",
  LIST_PAGE_TITLE: "Marketing Goods Requisition List",
  EDIT_PAGE_TITLE: "Edit Marketing Goods Requisition",
  GET_ALL: "marketing-promotion-plan/good-requisition",
  GET_ALL_MAKE_PAYMENTS: "goods-request-note/payment",
  GET_ONE: "marketing-promotion-plan/good-requisition/",
  CREATE: "marketing-promotion-plan/good-requisition/create",
  UPDATE: "marketing-promotion-plan/good-requisition/update/",
  DELETE: "marketing-promotion-plan/good-requisition/delete/",
  ADD_NEW_TITLE: "Add New Goods Request Note",
};
