import React from "react";
import DataTable from "react-data-table-component";
import ItemListPagination from "../trip/ItemListPagination";
import { thousandSeperator } from "@utils/general/digit-separators";

const ItemListTable = (props: { data: any[] }) => {
  return (
    <DataTable
      conditionalRowStyles={[
        {
          when: (row) => row.product_code === "Totals:",
          style: {
            backgroundColor: "#f3f4f6",
            fontWeight: 500,
          },
        },
      ]}
      columns={[
        {
          name: "Product ID",
          selector: (row: any) => row.product_code,
        },
        {
          name: "Product Name",
          selector: (row: any) => row.product_name,
        },
        {
          name: "Allocation Account",
          selector: (row: any) => row.warehouse_name,
          wrap: true,
        },
        {
          name: "Qty",
          selector: (row: any) =>
            row.noAction ? null : thousandSeperator(row.qty || 0),
        },
        {
          name: "Sale Price",
          selector: (row: any) =>
            row.noAction ? null : thousandSeperator(row.sales_price || 0),
        },
        {
          name: "Discount",
          selector: (row: any) => (
            <div className="flex items-center ">
              <div>
                {row.discount} {row.discount_type}
              </div>
            </div>
          ),
        },

        {
          name: "Amount",
          selector: (row: any) => thousandSeperator(row.amount || 0),
        },
      ]}
      data={props.data}
      striped
      responsive
      // pagination
      // paginationComponent={() => <ItemListPagination />}
    />
  );
};

export default ItemListTable;
