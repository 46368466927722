import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { FieldErrors, Controller, Control } from "react-hook-form";
import { isNumeric } from "../../utils/general/isNumeric";

interface CustomSelectFieldProps {
  name: string;
  options: any[] | undefined;
  label?: string;
  value?: any;
  noLabel?: boolean;
  defaultValue?: any;
  control: Control;
  errors: FieldErrors<any>;
  disabled?: boolean;
  colSpan?: number;
  required?: boolean;
  classNames?: string;
  onSelectChange?: any;
  onMenuScrollToBottom?: any;
}

const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  name,
  options,
  label,
  value,
  noLabel,
  defaultValue,
  control,
  errors,
  disabled,
  colSpan,
  required,
  classNames,
  onSelectChange,
  onMenuScrollToBottom,
}) => {
  // const targetOption = options?.find((option) => option.value === value);
  const targetOption = options?.find(
    (option) => option.value === String(value)
  ); // Normalize to string

  // console.log("value", value);
  // console.log("option", options);
  // console.log("targetOption", targetOption);
  // console.log("name", name);

  if (!options) {
    return (
      <div
        style={{
          gridColumn:
            colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
        }}
        className={`flex flex-col p-2 ${classNames}`}
      >
        {!noLabel ? (
          <label
            className="mb-2 text-gray-700"
            style={{ textTransform: "capitalize" }}
            htmlFor="name"
          >
            {label || name}
          </label>
        ) : null}

        <Select
          menuPortalTarget={document.body}
          isDisabled={disabled}
          styles={{
            control: (provided) => ({
              ...provided,
              paddingTop: "0.19rem",
              paddingBottom: "0.19rem",
              borderColor: "rgb(128,149,187)",
              borderWidth: "0.5px",
            }),
            option: (base, { data, isDisabled, isFocused, isSelected }) => ({
              ...base,
              backgroundColor: isSelected
                ? "rgb(45,120,219)"
                : isFocused
                ? "#e5e7eb"
                : "",
              color: isSelected ? "#ffffff" : "",
              zIndex: 999999,
            }),
          }}
          options={[]}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        gridColumn: colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
      }}
      className={`flex flex-col p-2 ${classNames}`}
    >
      {!noLabel ? (
        <label
          className="mb-2 text-gray-700"
          style={{ textTransform: "capitalize" }}
          htmlFor="name"
        >
          {label || name}
          {required ? (
            <span className="ml-[0.15rem] text-red-600 text-md">&#42;</span>
          ) : null}
        </label>
      ) : null}

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            menuPortalTarget={document.body}
            isDisabled={disabled}
            styles={{
              control: (provided) => ({
                ...provided,
                paddingTop: "0.19rem",
                paddingBottom: "0.19rem",
                borderColor: "rgb(128,149,187)",
                borderWidth: "0.5px",
              }),
              option: (base, { data, isDisabled, isFocused, isSelected }) => ({
                ...base,
                backgroundColor: isSelected
                  ? "rgb(45,120,219)"
                  : isFocused
                  ? "#e5e7eb"
                  : "",
                color: isSelected ? "#ffffff" : "",
              }),
            }}
            defaultValue={targetOption || null}
            inputId={name}
            onMenuScrollToBottom={
              onMenuScrollToBottom ? () => onMenuScrollToBottom() : undefined
            }
            // name={name}
            value={
              options?.find((option) => option.value == value) ||
              targetOption ||
              null
            }
            onBlur={onBlur}
            onChange={(val) => {
              if (isNumeric(val.value)) {
                val.value = parseInt(val.value);
              }
              onChange(val.value); // Ensure you're updating the value correctly in react-hook-form

              // Call the custom onSelectChange function if it exists
              if (onSelectChange) {
                onSelectChange(val.value); // Trigger the action you want
              }
            }}
            options={options}
          />
        )}
      />
      {errors[name] && (
        <div className="mt-2 font-[300] italic text-red-600">
          {/* @ts-ignore */}
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default CustomSelectField;
