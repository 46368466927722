import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DeleteButton from "../../buttons/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";
import {
  addCities,
  addFormValues,
  addProductDetails,
  addStates,
  addTownships,
  deleteRegionDetail,
  emptyRegionDetail,
  resetFormValues,
  updateProductDetail,
} from "@states/common/common.slice";
import {
  addRegions,
  emptyWayDetails,
  removeRegion,
} from "@states/common/common.slice";
import { useFieldArray, useForm } from "react-hook-form";
import CustomSelectField from "@components/form/CustomSelectField";
import useGetAllProducts from "@hooks/product/useGetAllProducts";
import CustomSearchModalChooseField from "@components/form/CustomSearchModalChooseField";
import CustomInputField from "@components/form/CustomInputField";
import { tripSchema } from "@helpers/validation-schemas/trip/trip.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLazyGetAllProductByWarehouseIdsQuery } from "@states/common/common.api";

const TripProductDetailTable = ({
  warehouses,
  append,
  remove,
  update,
  register,
  errors,
  getValues,
  setError,
  watch,
  setValue,
  control,
  isDisabled = false,
}: any) => {
  //@ts-ignore
  const dispatch = useDispatch<AppDispatch>();
  const productDetails = watch("product_detail") || [];
  console.log("watching productDetails", productDetails);
  // Fetch products when warehouse selection changes
  const [fetchProducts, { data: productData, isLoading, error }] =
    useLazyGetAllProductByWarehouseIdsQuery();

  // Fetch products when warehouses change
  useEffect(() => {
    if (warehouses.length > 0) {
      fetchProducts({
        warehouseIds: warehouses.map((wh: any) => wh.warehouse_id),
      });
    }
  }, [warehouses, fetchProducts]);

  const handleAppend = () => {
    append({
      amount: 0,
      product_id: "",
      qty: 0,
      unit_id: "",
      unit_name: "",
      product_code: "",
      product_name: "",
      product_category_id: "",
      product_category_name: "",
      pack_type_id: "",
      product_type_name: "",
      api_level: "",
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    //@ts-ignore
    const subscription = watch((value, { name, type }) => {
      const { product_detail } = value;

      // Ensure name follows the expected format
      const nameParts = name?.split(".") || [];
      const targetIndex = nameParts.length > 1 ? Number(nameParts[1]) : -1;
      const changeColumn = nameParts.length > 2 ? nameParts[2] : null;

      // Validate targetIndex
      if (
        isNaN(targetIndex) ||
        targetIndex < 0 ||
        !product_detail?.[targetIndex]
      )
        return;

      let targetProductDetail = product_detail[targetIndex];

      if (changeColumn === "product_id") {
        const foundProduct = productData?.data?.find(
          (product: any) => product.product_id == targetProductDetail.product_id
        );

        if (!foundProduct) return;

        targetProductDetail = {
          product_id: foundProduct.product_id,
          unit_id: foundProduct.unit_id,
          warehouse_id: targetProductDetail.warehouse_id,
          sales_price: foundProduct.secondary_sales_price,
          qty: targetProductDetail.qty || 0,
          product_name: foundProduct.product_name,
          unit_name: foundProduct.unit_name,
          unit_type_data: foundProduct.unit_type_data,
          pack_type_id: foundProduct.pack_type_id,
          product_type_name: foundProduct.product_type_name,
          product_category_id: foundProduct.product_category_id,
          product_category_name: foundProduct.product_category_name,
          api_level: foundProduct.api_level,
        };
      }

      // Update only if targetIndex is valid
      if (!isNaN(targetIndex) && targetIndex >= 0) {
        update(targetIndex, { ...targetProductDetail });
      }
    });

    return () => {
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch, productData]);

  return (
    <>
      <div className="p-8 rounded-md flex justify-between items-center ">
        <h3 className="mb-6 text-lg font-semibold text-primary-dark">
          Trip Product Detail List
        </h3>
        <h4
          onClick={handleAppend}
          className="mr-4 cursor-pointer font-[500] text-primary-dark text-[1rem]"
        >
          Add More Product +
        </h4>
      </div>
      <div className="-mt-4 col-span-full">
        <div className="w-[100%] mx-auto cursor-grab active:cursor-grabbing overflow-x-auto hide-scrollbar">
          <table className="min-w-full overflow-x-scroll custom-table">
            <thead className="h-12 py-3 text-xs font-light text-netural-600 bg-neutral-100">
              <th className="text-xs font-light 2xl:text-sm ">Product Name</th>

              <th className="text-xs font-light 2xl:text-sm">
                Product Category
              </th>
              <th className="text-xs font-light 2xl:text-sm">Pack Type</th>
              <th className="text-xs font-light 2xl:text-sm">API Level</th>
              <th className="text-xs font-light 2xl:text-sm">Unit</th>

              <th className="text-xs font-light 2xl:text-sm">Qty</th>

              <th className="text-xs font-light 2xl:text-sm">Action</th>
            </thead>
            <tbody className="text-center">
              {productDetails.length > 0 &&
                productDetails.map((product_detail: any, i: number) => {
                  const options = product_detail?.unit_type_data?.map(
                    (unit: any) => ({
                      label: unit.unit_name,
                      value: unit.unit_id,
                    })
                  );
                  return (
                    <tr key={i}>
                      <td>
                        <CustomSelectField
                          options={productData?.data.map((product: any) => {
                            return {
                              label: ` (${product.product_code})`,
                              value: product.product_id,
                            };
                          })}
                          classNames="w-[100%] min-w-[300px]"
                          noLabel
                          disabled={isDisabled}
                          errors={errors}
                          name={`product_detail.${i}.product_id`}
                          //@ts-ignore
                          control={control}
                        />
                      </td>

                      <td>
                        <CustomInputField
                          readOnly
                          disabled
                          noLabel
                          classNames="w-[100%]"
                          errors={errors}
                          name={`product_detail.${i}.product_category_id`}
                          register={register}
                          setValue={setValue}
                          value={product_detail.product_category_name}
                          placeHolder="Enter Product Category Name"
                        />
                      </td>
                      <td>
                        <CustomInputField
                          readOnly
                          disabled
                          noLabel
                          classNames="w-[100%]"
                          errors={errors}
                          name={`product_detail.${i}.pack_type_id`}
                          register={register}
                          setValue={setValue}
                          value={product_detail.product_type_name}
                          placeHolder="Enter Pack Type"
                        />
                      </td>
                      <td>
                        <CustomInputField
                          readOnly
                          disabled
                          noLabel
                          classNames="w-[100%]"
                          errors={errors}
                          name={`product_detail.${i}.api_level`}
                          register={register}
                          setValue={setValue}
                          value={product_detail.api_level}
                          placeHolder="Enter API Level"
                        />
                      </td>
                      <td>
                        <CustomSelectField
                          noLabel
                          options={options}
                          classNames="w-[100%] min-w-[300px]"
                          errors={errors}
                          name={`product_detail.${i}.unit_id`}
                          control={control}
                          value={product_detail?.unit_id}
                          disabled={isDisabled}
                        />
                      </td>

                      <td>
                        <CustomInputField
                          inputType="number"
                          noLabel
                          classNames="min-w-[100px] w-[100%]"
                          errors={errors}
                          name={`product_detail.${i}.qty`}
                          register={register}
                          setValue={setValue}
                          value={product_detail.qty}
                          disabled={isDisabled}
                          min={1}
                          placeHolder="Enter Qty"
                        />
                      </td>

                      <td>
                        <div
                          style={{
                            width: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <DeleteButton
                            // disabled={muted}
                            id={i}
                            actionHandler={() => handleRemove(i)}
                            disabled={isDisabled}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TripProductDetailTable;
