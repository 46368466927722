import NoRecord from '@components/layouts/NoRecord';
import { TWayUserAssignDetail } from '@type-defs/distribution-region/TWayUserAssignDetail';
import DataTable from 'react-data-table-component';

const textColor = 'text-primary-dark';
interface TTWarehouse {
    warehouse_id : string | number,
    warehouse_name : string ,
}
const TripWarehouseDetail = (props: {
    warehouseData: any;
}) => {
    const { warehouseData } = props;

    if (!warehouseData) {
        return <div>No record found.</div>;
    }
    const columns = [
        {
            name: 'Allocation Account Name',
            selector: (row: any) => row.warehouse_name,
            sort: true,
        }
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={warehouseData}
                responsive
                striped
                fixedHeader={true}
                fixedHeaderScrollHeight='500px'
            />
        </>
    );
};

export default TripWarehouseDetail;
