import { z } from "zod";

export const saleOrderSchema = z
  .object({
    sales_date: z.union(
      [
        z.string({
          message: "sales date must be a valid date",
        }),
        z.date({ message: "sales date must be a valid date" }),
      ],
      {
        message: "sales date must be a valid date",
      }
    ),

    sale_promotion_id: z.union([
      z.string({
        message: "Sale Promotion is required",
      }),
      z.number({ message: "Sale Promotion is required" }),
    ]),
    customer_id: z.union([
      z.string({
        message: "customer name is required",
      }),
      z.number({ message: "customer name is required" }),
    ]),
    business_unit_id: z.union([
      z.string({ message: "Business Unit Name is required" }),
      z.number({ message: "Business Unit Name is required" }),
    ]),
    consignment_contract_id: z.union([z.string(), z.number()]).optional(),
    payment_type_id: z.union([
      z.string({
        message: "Payment type is required",
      }),
      z.number({
        message: "Payment type is required",
      }),
    ]),
    sale_type_id: z.union([
      z.string({
        message: "Sale type is required",
      }),
      z.number({
        message: "Sale type is required",
      }),
    ]),
    payment_terms_id: z
      .union([
        z.string({
          message: "Payment Term is required",
        }),
        z.number({
          message: "Payment Term is required",
        }),
      ])
      .optional(),
    remark: z.string().optional(),
    description: z.string().optional(),
    product_detail: z.array(
      z.object({
        product_id: z.union([
          z.string().min(1, { message: "Choose The Product" }),
          z.number().min(1, { message: "Choose The Product" }),
        ]),

        unit_id: z.union([z.string(), z.number()]),
        sales_price: z.number(),
        qty: z.number().min(1, {
          message: "Qty must be greater than 0",
        }),
        available_qty: z.number().optional().nullable(),
        total_amount: z.number(),
        product_code: z.string().optional(),
        product_name: z.string().optional(),
        unit_name: z.string().optional(),
        amount: z.number().optional().default(0),
        discount: z.number().optional().default(0),
        discount_amount: z.number().default(0),
        discount_type: z.string().optional().default("AMT"),
        tax: z.number().optional(),
        tax_amount: z.number().default(0),
        tax_type: z.string().optional().default("AMT"),
        warehouse_id: z
          .union([
            z
              .string()
              .min(1, { message: "Choose The Allocation Account Name" }),
            z
              .number()
              .min(1, { message: "Choose The Allocation Account Name" }),
          ])
          .optional(),
        warehouse_name: z.string().optional(),
      })
    ),
    tax: z.any().optional().default(0),
    tax_type: z.string().optional(),
    tax_amount: z.number().default(0),
    discount: z.any().optional().default(0),
    discount_type: z.string().optional(),
    discount_amount: z.number().default(0),
    other_charges: z.any().optional().default(0),
    grand_total_amount: z.number().default(0),
    sub_total: z.number().default(0),
    current_amount: z.any().optional().nullable(),
    credit_balance: z.number().optional().nullable(),
  })
  .refine(
    (data) => data.payment_type_id !== 3 || data.payment_terms_id !== null,
    {
      message: "Payment terms is required",
      path: ["payment_terms_id"],
    }
  );

export type ZSaleOrder = z.infer<typeof saleOrderSchema>;

export interface TPrimarySaleOrder extends ZSaleOrder {
  primary_sales_order_id: number | string;
  primary_sales_order_code: string;
  customer_first_name: string;
  customer_last_name: string;
  payment_type_name: string;
  sale_promotion_id: string | number;
  status: string | number;
  reason: string | null;
  formatted_sales_date: string;
  way_name: string;
  credit_limit: number;
  payment_terms_id?: string | number;
}
export interface TSecondarySaleOrder extends ZSaleOrder {
  secondary_sales_order_id: number | string;
  secondary_sales_order_code: string;
  business_unit_name?: string;
  customer_first_name: string;
  customer_last_name: string;
  payment_type_name: string;
  payment_terms_id?: string | number;
  status: string | number;
  reason: string | null;
  formatted_sales_date: string;
  way_name: string;
  credit_limit: number;
}
export interface TDirectSaleOrder extends ZSaleOrder {
  direct_sales_order_id: number | string;
  direct_sales_order_code: string;
  customer_first_name: string;
  customer_last_name: string;
  payment_type_name: string;
  reason: string | null;
  status: string | number;
  formatted_sales_date: string;
  payment_terms_id?: string | number;
  way_name: string;
  credit_limit: number;
}
export interface TTripSalesOrder extends ZSaleOrder {
  trip_sale_id: number | string;
  trip_sale_code: string;
  customer_first_name: string;
  customer_last_name: string;
  business_unit_name: string;
  payment_type_name: string;
  status: string | number;
  reason: string | null;
  formatted_sales_date: string;
  way_name: string;
  credit_limit: number;
}
