import { z } from "zod";

export const consignmentReturnSchema = z.object({
  consignment_contract_id: z.union([
    z.string({
      message: "Consignment Contract ID  is required",
    }),
    z.number({
      message: "Consignment Contract ID  is required",
    }),
  ]),
  customer_id: z.union([z.string(), z.number()]).optional(),
  return_reason_id: z.union([
    z.string({
      message: "Return Reason is required",
    }),
    z.number({
      message: "Return Reason is required",
    }),
  ]),
  return_date: z.union(
    [
      z.string({
        message: "Return date  is required",
      }),
      z.date(),
    ],
    {
      message: "Return date must be a valid date",
    }
  ),
  sub_total: z.number().default(0),
  other_charges: z.number().optional().default(0),
  grand_total_amount: z.number().default(0),
  return_deduct: z.number().optional().default(0),
  return_deduct_method: z.string(),
  return_deduct_amount: z.number().optional(),
  total_return_amount: z.number().optional(),
  balance: z.number().optional(),
  remark: z.string().optional(),
  description: z.string().optional().nullable(),
  product_detail: z.array(
    z.object({
      product_id: z.union([z.string(), z.number()]),
      warehouse_id: z.union([z.string(), z.number()]),
      unit_id: z.union([z.string(), z.number()]),
      sales_price: z.number(),
      qty: z.number(),
      total_amount: z.number(),
      product_code: z.string().optional(),
      product_name: z.string().optional(),
      unit_name: z.string().optional(),
      amount: z.number().optional().default(0),
      discount: z.number().optional().default(0),
      discount_amount: z.number().default(0).optional(),
      discount_type: z.union([z.string(), z.null()]).default("AMT"),
      tax: z.number().optional(),
      tax_amount: z.number().default(0).optional(),
      tax_type: z.union([z.string(), z.null()]).default("AMT"),
      return_qty: z.number(),
      return_amount: z.number(),
    })
  ),
});
//   .superRefine((val: any, ctx: any) => {
//     if (
//       val.return_reason_id == 4 &&
//       (!val.description || val.description.trim() === "")
//     ) {
//       ctx.addIssue({
//         code: z.ZodIssueCode.custom,
//         path: ["description"],
//         message: "Description is required when Return Reason is 4",
//       });
//     }
//   });

export type ZConsignmentReturn = z.infer<typeof consignmentReturnSchema>;

export interface TConsignmentReturn extends ZConsignmentReturn {
  consignment_return_id: string | number;
  consignment_return_code: string;
  customer_name: string;
  status: number | string;
}
