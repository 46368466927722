import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { CONSIGNMENT_RETURN } from "@utils/constants/consignment-sales-return /consignment-return.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const consignmentReturnApi = createApi({
  reducerPath: "consignmentReturnApi",
  tagTypes: ["ConsignmentReturn"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllConsignmentReturns: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
        from_date?: string;
        to_date?: string;
        customer_id?: string | number;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        search,
        from_date,
        to_date,
        customer_id,
      }) => {
        const queryParams = {
          url: CONSIGNMENT_RETURN.GET_ALL,
          params: { page, limit },
        };
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (customer_id) {
          //@ts-ignore
          queryParams.params.customer_id = customer_id;
        }

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        return queryParams;
      },
    }),

    getConsingmentReturn: builder.query<
      any,
      { consignment_return_id: string | number }
    >({
      query: ({ consignment_return_id }) =>
        `${CONSIGNMENT_RETURN.GET_ONE}${consignment_return_id}`,
    }),
    createConsignmentReturn: builder.mutation({
      query: (body) => ({
        url: CONSIGNMENT_RETURN.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateConsignmentReturn: builder.mutation({
      query: ({ id, body }) => ({
        url: `${CONSIGNMENT_RETURN.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteConsignmentReturn: builder.mutation({
      query: ({ id, body }) => ({
        url: `${CONSIGNMENT_RETURN.DELETE}${id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllConsignmentReturnsQuery,
  useGetConsingmentReturnQuery,
  useCreateConsignmentReturnMutation,
  useUpdateConsignmentReturnMutation,
  useDeleteConsignmentReturnMutation,
} = consignmentReturnApi;
