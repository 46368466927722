import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { CONSIGNMENT } from "@utils/constants/consignment/consignment.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const consignmentApi = createApi({
  reducerPath: "consignmentApi",
  tagTypes: ["Consignment"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllConsignment: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
        customer_id?: string | number;
        status?: string | number;
        payment_type_id?: string | number;
        customer_name?: string;
        region_id?: string | number;
        search?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        from_date,
        to_date,
        customer_id,
        status,
        payment_type_id,
        customer_name,
        region_id,
        search,
      }) => {
        const queryParams = {
          url: CONSIGNMENT.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (customer_name) {
          //@ts-ignore
          queryParams.params.customer_name = customer_name;
        }
        if (region_id) {
          //@ts-ignore
          queryParams.params.region_id = region_id;
        }

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (customer_id) {
          //@ts-ignore
          queryParams.params.customer_id = customer_id;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }
        if (payment_type_id) {
          //@ts-ignore
          queryParams.params.payment_type_id = payment_type_id;
        }

        return queryParams;
      },
    }),
    // getConsignmentContractCustomer: builder.query<any, any>({
    //     query: ({page=1,limit=10}) => `${CONSIGNMENT.BASE_PATH}consignment/customer`,
    // }),
    getConsignmentContractCustomer: builder.query<any, any>({
      query: ({ page = 1, limit = 10 }) => {
        const queryParams = {
          url: `${CONSIGNMENT.BASE_PATH}customer`,
          params: { page, limit },
        };
        // `${CONSIGNMENT.BASE_PATH}consignment/customer`
        return queryParams;
      },
    }),

    getConsignmentContractProductDetail: builder.query<
      any,
      { consignment_contract_id: string | number }
    >({
      query: ({ consignment_contract_id }) => {
        return `${CONSIGNMENT.BASE_PATH}contract/edit/${consignment_contract_id}`;
      },
    }),

    getConsignmentContractListByBu: builder.query<
      any,
      { business_unit_id: string | number }
    >({
      query: ({ business_unit_id }) => {
        return `${CONSIGNMENT.BASE_PATH}contract/business-unit/${business_unit_id}`;
      },
    }),
    getConsingment: builder.query<any, { consignment_id: string | number }>({
      query: ({ consignment_id }) => `${CONSIGNMENT.GET_ONE}${consignment_id}`,
    }),
    createConsignment: builder.mutation({
      query: (body) => ({
        url: CONSIGNMENT.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateConsignment: builder.mutation({
      query: ({ id, body }) => ({
        url: `${CONSIGNMENT.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    updateConsignmentApproval: builder.mutation({
      query: ({ id, body }) => ({
        url: `${CONSIGNMENT.APPROVE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteConsignment: builder.mutation({
      query: (id) => {
        return {
          url: `${CONSIGNMENT.DELETE}${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllConsignmentQuery,
  useGetConsingmentQuery,
  useLazyGetConsignmentContractListByBuQuery,
  useGetConsignmentContractCustomerQuery,
  useGetConsignmentContractProductDetailQuery,
  useLazyGetConsignmentContractProductDetailQuery,
  useCreateConsignmentMutation,
  useUpdateConsignmentMutation,
  useUpdateConsignmentApprovalMutation,
  useDeleteConsignmentMutation,
} = consignmentApi;
