export const MARKETING_PROMOTION_PLAN = {
  PRIMARY_KEY: "user_id",
  BASE_PATH: "/marketing-promotion/plans/",
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  RESET_PASSWORD_PATH(id: string | number) {
    return this.BASE_PATH + id + "/reset-password";
  },
  ADD_PAGE_TITLE: "Add New Marketing Promotion Plan",
  LIST_PAGE_TITLE: "Marketing Promotion Plan List",
  EDIT_PAGE_TITLE: "Edit Marketing Promotion Plan",
  ADD_NEW_TITLE: "Add New Marketing Promotion Plan",
  GET_ALL: "/marketing-promotion-plan",
  GET_ONE(id: string | number) {
    return `/marketing-promotion-plan/edit/${id}`;
  },
  CREATE: "/marketing-promotion-plan/create",
  UPDATE: "/marketing-promotion-plan/update/",
  DELETE: "/marketing-promotion-plan/delete/",
};
