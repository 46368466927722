import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { PRIMARY_SALE_ORDER } from "@utils/constants/primary-sale-order.constants";
import { TRIP_SALES_ORDER } from "@utils/constants/trip/trip-sales-order.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const tripSalesOrderApi = createApi({
  reducerPath: "tripSalesOrderApi",
  tagTypes: ["TripSalesOrder"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllTripSalesOrder: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        customer_name?: string;
        trip_sales_order_code?: string;
        customer_id?: string | number;
        payment_type_id?: string | number;
        from_date?: Date | string;
        to_date?: Date | string;
        search?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        customer_name,
        trip_sales_order_code,
        customer_id,
        search,
        payment_type_id,
        from_date,
        to_date,
      }) => {
        const queryParams = {
          url: TRIP_SALES_ORDER.GET_ALL,
          params: { page, limit },
        };
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        if (customer_name) {
          //@ts-ignore
          queryParams.params.customer_name = customer_name;
        }

        if (trip_sales_order_code) {
          //@ts-ignore
          queryParams.params.trip_sales_order_code = trip_sales_order_code;
        }

        if (customer_id) {
          //@ts-ignore
          queryParams.params.customer_id = customer_id;
        }

        if (payment_type_id) {
          //@ts-ignore
          queryParams.params.payment_type_id = payment_type_id;
        }

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    getTripSalesOrder: builder.query<any, { id: string | number }>({
      query: ({ id }) => `${TRIP_SALES_ORDER.GET_ONE}${id}`,
    }),
    createTripSalesOrder: builder.mutation({
      query: (body) => ({
        url: TRIP_SALES_ORDER.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateTripSalsOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `${TRIP_SALES_ORDER.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    // approvePrimaryOrder: builder.mutation({
    //     query: ({ id, body }) => ({
    //         url: `${PRIMARY_SALE_ORDER.APPROVE}${id}`,
    //         method: 'PATCH',
    //         body,
    //     }),
    // }),
    deleteTripSalesOrder: builder.mutation({
      query: (id) => ({
        url: `${TRIP_SALES_ORDER.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllTripSalesOrderQuery,
  useGetTripSalesOrderQuery,
  useCreateTripSalesOrderMutation,
  useUpdateTripSalsOrderMutation,
  useDeleteTripSalesOrderMutation,
} = tripSalesOrderApi;
