import { TTripSalesOrder } from "@helpers/validation-schemas/primary-sale-order/sale-order.schema";
import { useGetTripSalesOrderQuery } from "@states/trip/trip-sales-order.api";
import { useGetOneVehicleQuery } from "@states/trip/vehicle.api";
import { TGetOneSuccessResponse } from "@type-defs/general/TGetOneSuccessResponse";

const useGetOneVehicle = (id: string | number) => {
  const {
    data: rawData,
    error,
    isLoading,
    refetch,
  } = useGetOneVehicleQuery({
    id: id!,
  });

  const vehicleResponse = rawData as any as TGetOneSuccessResponse<any>;

  return {
    vehicleData: vehicleResponse?.data,
    vehicleMessage: vehicleResponse?.message,
    vehicleSuccess: vehicleResponse?.success,
    vehicleError: error,
    vehicleLoading: isLoading,
    vehicleRefetch: refetch,
  };
};

export default useGetOneVehicle;
